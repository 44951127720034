// src/components/tasks/TaskList.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Search, Plus, Clock, AlertCircle, LayoutGrid, Table as TableIcon } from 'lucide-react';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import KanbanView from './KanbanView';
import api from '../../lib/axios';

const STATUS_FILTERS = [
  { key: 'all', label: 'All' },
  { key: 'TODO', label: 'To Do', class: 'bg-blue-100 text-blue-800' },
  { key: 'IN_PROGRESS', label: 'In Progress', class: 'bg-yellow-100 text-yellow-800' },
  { key: 'COMPLETED', label: 'Completed', class: 'bg-green-100 text-green-800' }
];

const PRIORITY_CLASSES = {
  HIGH: 'bg-red-100 text-red-800',
  MEDIUM: 'bg-yellow-100 text-yellow-800',
  LOW: 'bg-blue-100 text-blue-800'
};

const TaskList = ({ projectId, isManager, onAddTask, onViewTask, onEditTask, teamMembers, refreshTrigger}) => {
  console.log('TaskList props:', { projectId, isManager, onViewTask }); // Debug log
  
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [view, setView] = useState('table');

  const handleTaskClick = useCallback((task) => {
    console.log('Task clicked:', task); // Debug log
    if (onViewTask) {
      onViewTask(task);
    }
  }, [onViewTask]);

  // Fetch tasks
  const fetchTasks = useCallback(async () => {
    if (!projectId) return;
    
    try {
      setLoading(true);
      const response = await api.get('/api/tasks/');
      // Filter tasks for current project
      const projectTasks = response.data.filter(task => task.project === projectId);
      setTasks(projectTasks);
    } catch (err) {
      setError('Failed to load tasks');
      console.error('Error fetching tasks:', err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks, refreshTrigger]);

  // Filter tasks
  const filterTasks = useCallback(() => {
    let filtered = [...tasks];

    // Apply status filter
    if (selectedStatus !== 'all') {
      filtered = filtered.filter(task => task.status === selectedStatus);
    }

    // Apply search filter
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(task => 
        task.name.toLowerCase().includes(query) ||
        task.description?.toLowerCase().includes(query) ||
        task.assignee_name?.toLowerCase().includes(query) ||
        task.tags?.some(tag => tag.toLowerCase().includes(query))
      );
    }

    setFilteredTasks(filtered);
  }, [tasks, selectedStatus, searchQuery]);

  // Effects
  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    filterTasks();
  }, [filterTasks, tasks]);

  // Loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <CircularProgress />
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="text-center py-8 text-red-500">{error}</div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Status filters and controls */}
      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex gap-2 overflow-x-auto pb-2 sm:pb-0">
          {STATUS_FILTERS.map(status => (
            <button
              key={status.key}
              onClick={() => setSelectedStatus(status.key)}
              className={`
                px-3 py-1.5 rounded-lg text-sm font-medium transition-colors whitespace-nowrap
                ${selectedStatus === status.key
                  ? status.class || 'bg-gray-200 text-gray-800'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }
              `}
            >
              {status.label} ({tasks.filter(task => 
                status.key === 'all' ? true : task.status === status.key
              ).length})
            </button>
          ))}
        </div>

        <div className="flex gap-2">
          {/* View Toggle */}
          <div className="flex items-center bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setView('table')}
              className={`p-1.5 rounded ${
                view === 'table'
                ? 'bg-white text-blue-600 shadow-sm'
                : 'text-gray-600 hover:text-gray-900'
              }`}
              title="Table View"
            >
              <TableIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setView('kanban')}
              className={`p-1.5 rounded ${
                view === 'kanban'
                ? 'bg-white text-blue-600 shadow-sm'
                : 'text-gray-600 hover:text-gray-900'
              }`}
              title="Kanban View"
            >
              <LayoutGrid className="w-5 h-5" />
            </button>
          </div>

          {isManager && (
            <button
              onClick={() => onAddTask()}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="w-4 h-4" />
              Add Task
            </button>
          )}
        </div>
      </div>

      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search tasks by name, description, assignee, or tags..."
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      {/* Task List */}
      {filteredTasks.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          {searchQuery 
            ? 'No tasks found matching your search'
            : 'No tasks found for the selected status'
          }
        </div>
      ) : view === 'table' ? (
        // Table View
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Task</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Priority</TableHead>
              <TableHead>Assignee</TableHead>
              <TableHead>Due Date</TableHead>
              <TableHead>Progress</TableHead>
              <TableHead>Time</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredTasks.map((task) => (
              <TableRow 
                key={task.id} 
                onClick={() => handleTaskClick(task)} 
                className="cursor-pointer hover:bg-gray-50"
              >
                <TableCell>
                  <div className="min-w-[200px]">
                    <p className="font-medium text-gray-900">{task.name}</p>
                    {task.tags && task.tags.length > 0 && (
                      <div className="flex gap-1 mt-1">
                        {task.tags.map(tag => (
                          <span 
                            key={tag}
                            className="px-2 py-0.5 text-xs rounded-full bg-gray-100 text-gray-600"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    STATUS_FILTERS.find(s => s.key === task.status)?.class
                  }`}>
                    {task.status_display}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    PRIORITY_CLASSES[task.priority]
                  }`}>
                    {task.priority_display}
                  </span>
                </TableCell>
                <TableCell>
                  {task.assignee_name || '-'}
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {task.is_overdue && (
                      <AlertCircle className="w-4 h-4 text-red-500" />
                    )}
                    <span className={task.is_overdue ? 'text-red-500' : ''}>
                      {format(new Date(task.due_date), 'MMM d, yyyy')}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className="bg-blue-600 h-2 rounded-full"
                      style={{ width: `${task.completion_percentage}%` }}
                    />
                  </div>
                  <span className="text-xs text-gray-500 mt-1">
                    {task.completion_percentage}%
                  </span>
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2 text-gray-500">
                    <Clock className="w-4 h-4" />
                    <span>{task.estimated_hours}h est.</span>
                  </div>
                  {task.total_time_spent > 0 && (
                    <div className="text-xs text-gray-500 mt-1">
                      {task.total_time_spent}h spent
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        // Kanban View
        <KanbanView
          tasks={filteredTasks}
          isManager={isManager}
          onAddTask={(status) => onAddTask(status)}
          onViewTask={handleTaskClick}
        />
      )}
    </div>
  );
};

export default TaskList;