// src/pages/time-tracking/TimeTracking.jsx
import React, { useState } from 'react';
import { format } from 'date-fns';
import { Calendar, Clock, Table } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';

import ViewToggle from '../../components/time-tracking/ViewToggle';
import DailyView from '../../components/time-tracking/DailyView';
import WeeklyView from '../../components/time-tracking/WeeklyView';
import MonthlyView from '../../components/time-tracking/MonthlyView';
import AddTimeModal from '../../components/time-tracking/AddTimeModal';

const TimeTracking = () => {
  const [view, setView] = useState('daily');
  const [isAddTimeModalOpen, setIsAddTimeModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleAddTime = (projectId, taskId) => {
    setSelectedProject(projectId);
    setSelectedTask(taskId);
    setIsAddTimeModalOpen(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Time Tracking</h1>
          <p className="mt-1 text-sm text-gray-600">
            Track and manage your work hours
          </p>
        </div>

        {/* View Toggle and Add Time Button */}
        <div className="flex items-center gap-4">
          <ViewToggle
            view={view}
            onViewChange={setView}
            options={[
              { value: 'daily', label: 'Daily', icon: Clock },
              { value: 'weekly', label: 'Weekly', icon: Table },
              { value: 'monthly', label: 'Calendar', icon: Calendar }
            ]}
          />
          <button
            onClick={() => setIsAddTimeModalOpen(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Add Time
          </button>
        </div>
      </div>

      {/* Time Tracking View */}
      <Card>
        <CardHeader className="border-b border-gray-200">
          <CardTitle>
            {format(selectedDate, view === 'monthly' ? 'MMMM yyyy' : 'MMMM d, yyyy')}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0">
          {view === 'daily' && (
            <DailyView
              date={selectedDate}
              onAddTime={handleAddTime}
            />
          )}
          {view === 'weekly' && (
            <WeeklyView
              date={selectedDate}
              onAddTime={handleAddTime}
            />
          )}
          {view === 'monthly' && (
            <MonthlyView
              date={selectedDate}
              onAddTime={handleAddTime}
              onDateChange={handleDateChange}
            />
          )}
        </CardContent>
      </Card>

      {/* Add Time Modal */}
      {isAddTimeModalOpen && (
        <AddTimeModal
          isOpen={isAddTimeModalOpen}
          onClose={() => {
            setIsAddTimeModalOpen(false);
            setSelectedProject(null);
            setSelectedTask(null);
          }}
          date={selectedDate}
          initialProject={selectedProject}
          initialTask={selectedTask}
        />
      )}
    </div>
  );
};

export default TimeTracking;