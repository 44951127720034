// src/components/dashboard/ViewToggle.jsx
import React from 'react';
import { TableIcon, LineChart } from 'lucide-react';

const ViewToggle = ({ view, onViewChange }) => {
  return (
    <div className="inline-flex rounded-lg border border-gray-200 bg-white">
      <button
        className={`
          inline-flex items-center px-3 py-2 rounded-l-lg gap-2
          ${view === 'table' 
            ? 'bg-blue-50 text-blue-600' 
            : 'text-gray-600 hover:bg-gray-50'}
        `}
        onClick={() => onViewChange('table')}
      >
        <TableIcon className="w-4 h-4" />
        <span className="text-sm">Table</span>
      </button>
      <button
        className={`
          inline-flex items-center px-3 py-2 rounded-r-lg gap-2 border-l
          ${view === 'timeline' 
            ? 'bg-blue-50 text-blue-600' 
            : 'text-gray-600 hover:bg-gray-50'}
        `}
        onClick={() => onViewChange('timeline')}
      >
        <LineChart className="w-4 h-4" />
        <span className="text-sm">Timeline</span>
      </button>
    </div>
  );
};

export default ViewToggle;