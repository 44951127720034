// Regular stats images (exactly 4 for each type)
const statsHeadshots = [
    '/images/stats/headshots/headshot1.jpg',
    '/images/stats/headshots/headshot2.jpg',
    '/images/stats/headshots/headshot3.jpg',
    '/images/stats/headshots/headshot4.jpg'
  ];
  
  const statsScreenshots = [
    '/images/stats/screenshots/screenshot1.jpg',
    '/images/stats/screenshots/screenshot2.jpg',
    '/images/stats/screenshots/screenshot3.jpg',
    '/images/stats/screenshots/screenshot4.jpg'
  ];
  
  // Alert images (1 image per severity level per type)
  const alertPersons = {
    high: '/images/alerts/persons/high/person1.jpg',
    medium: '/images/alerts/persons/medium/person1.jpg'
  };
  
  const alertApplications = {
    medium: '/images/alerts/applications/medium/app1.jpg',
    low: '/images/alerts/applications/low/app1.jpg'
  };
  
  // Get stats monitoring images - always returns all 4 images
  export const getStatsImages = () => {
    const headshots = statsHeadshots.map((url, index) => ({
      id: `headshot-${index + 1}`,
      url,
      timestamp: new Date(Date.now() - (index * 15 * 60000)).toISOString() // 15 minutes apart
    }));
  
    const screenshots = statsScreenshots.map((url, index) => ({
      id: `screenshot-${index + 1}`,
      url,
      timestamp: new Date(Date.now() - (index * 15 * 60000)).toISOString() // 15 minutes apart
    }));
  
    return { headshots, screenshots };
  };
  
  // Get alert image based on type and severity
  export const getAlertImage = (type, severity) => {
    if (type === 'UNAUTHORIZED_PERSON') {
      return alertPersons[severity] || alertPersons.medium;
    } else if (type === 'UNAUTHORIZED_APP') {
      return alertApplications[severity] || alertApplications.medium;
    }
    return null;
  };