// src/components/tasks/KanbanView.jsx
import React from 'react';
import { Plus } from 'lucide-react';

const COLUMNS = [
  { id: 'TODO', title: 'To Do', color: 'bg-blue-50 border-blue-200' },
  { id: 'IN_PROGRESS', title: 'In Progress', color: 'bg-yellow-50 border-yellow-200' },
  { id: 'COMPLETED', title: 'Completed', color: 'bg-green-50 border-green-200' }
];

const KanbanView = ({ tasks, isManager, onAddTask, onViewTask }) => {
  const getTasksByStatus = (status) => {
    return tasks.filter(task => task.status === status);
  };

  return (
    <div className="grid grid-cols-3 gap-4 h-full max-h-[calc(100vh-300px)]">
      {COLUMNS.map(column => (
        <div
          key={column.id}
          className={`${column.color} rounded-lg border flex flex-col h-full`}
        >
          {/* Column Header */}
          <div className="flex items-center justify-between p-4 border-b shrink-0 bg-white bg-opacity-50">
            <h3 className="font-medium text-gray-900">
              {column.title}
              <span className="ml-2 text-sm text-gray-500">
                ({getTasksByStatus(column.id).length})
              </span>
            </h3>
            {isManager && (
              <button
                onClick={() => onAddTask(column.id)}
                className="p-1 hover:bg-white rounded-lg transition-colors"
                title={`Add task to ${column.title}`}
              >
                <Plus className="w-5 h-5" />
              </button>
            )}
          </div>

          {/* Task Cards - Scrollable Container */}
          <div className="flex-1 overflow-y-auto p-4">
            <div className="space-y-3">
              {getTasksByStatus(column.id).map(task => (
                <div
                  key={task.id}
                  onClick={() => onViewTask(task)}
                  className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 cursor-pointer hover:shadow-md transition-shadow"
                >
                  {/* Task Title & Priority */}
                  <div className="flex items-start justify-between mb-2">
                    <h4 className="font-medium text-gray-900">{task.name}</h4>
                    <span className={`px-2 py-1 text-xs rounded-full ${
                      task.priority === 'HIGH' 
                        ? 'bg-red-100 text-red-800'
                        : task.priority === 'MEDIUM'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {task.priority_display}
                    </span>
                  </div>

                  {/* Task Description */}
                  {task.description && (
                    <p className="text-sm text-gray-600 mb-3 line-clamp-2">
                      {task.description}
                    </p>
                  )}

                  {/* Task Meta */}
                  <div className="space-y-2">
                    {/* Progress Bar */}
                    <div className="w-full bg-gray-100 rounded-full h-1.5">
                      <div 
                        className="bg-blue-600 h-1.5 rounded-full transition-all duration-300"
                        style={{ width: `${task.completion_percentage}%` }}
                      />
                    </div>

                    {/* Assignee & Due Date */}
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-500">
                        {task.assignee_name || 'Unassigned'}
                      </span>
                      <span className={`${task.is_overdue ? 'text-red-600' : 'text-gray-500'}`}>
                        Due {new Date(task.due_date).toLocaleDateString()}
                      </span>
                    </div>

                    {/* Tags */}
                    {task.tags && task.tags.length > 0 && (
                      <div className="flex flex-wrap gap-1">
                        {task.tags.map(tag => (
                          <span 
                            key={tag}
                            className="px-2 py-0.5 text-xs bg-gray-100 text-gray-600 rounded-full"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {/* Empty State */}
              {getTasksByStatus(column.id).length === 0 && (
                <div className="text-center py-8 text-gray-500 text-sm">
                  No tasks in {column.title.toLowerCase()}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KanbanView;