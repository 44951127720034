import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/index.css'

// Prevent React double rendering in development
const strictMode = false

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  strictMode ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
)