import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import useAuthStore from '../../store/authStore';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const { login, isAuthenticated, requiresPasswordChange } = useAuthStore();

  // Debug logging for component state
  useEffect(() => {
    console.log('State monitoring:', {
      isAuthenticated,
      requiresPasswordChange,
      timestamp: new Date().toISOString()
    });
  }, [isAuthenticated, requiresPasswordChange]);

  // Separate useEffect for handling redirects
  useEffect(() => {
    const handleRedirect = async () => {
      if (isAuthenticated) {
        console.log('Preparing redirect with state:', {
          isAuthenticated,
          requiresPasswordChange,
          timestamp: new Date().toISOString()
        });

        // Add a small delay to ensure state is settled
        await new Promise(resolve => setTimeout(resolve, 100));

        // Double check the state
        const currentState = useAuthStore.getState();
        console.log('Current store state before redirect:', {
          storeIsAuthenticated: currentState.isAuthenticated,
          storeRequiresPasswordChange: currentState.requiresPasswordChange
        });

        if (currentState.requiresPasswordChange) {
          console.log('→ Redirecting to /change-password');
          navigate('/change-password', { replace: true });
        } else {
          console.log('→ Redirecting to /dashboard');
          navigate('/dashboard', { replace: true });
        }
      }
    };

    handleRedirect();
  }, [isAuthenticated, requiresPasswordChange, navigate]);

  // Clear error when inputs change
  const handleInputChange = (setter) => (e) => {
    setError('');
    setter(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await login(email, password);
      // Immediately check state after login
      const state = useAuthStore.getState();
      console.log('Immediate post-login state check:', {
        isAuthenticated: state.isAuthenticated,
        requiresPasswordChange: state.requiresPasswordChange,
        timestamp: new Date().toISOString()
      });
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to connect to server');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle redirects with state check
  useEffect(() => {
    if (isAuthenticated) {
      console.log('Authenticated, checking redirect:', { requiresPasswordChange });
    }
  }, [isAuthenticated, requiresPasswordChange]);

  if (isAuthenticated) {
    console.log('Component is authenticated, not rendering form');
    return null;
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Welcome to Trekko</h2>
          <p className="mt-2 text-gray-600">Sign in to your account</p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label 
                htmlFor="email" 
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={handleInputChange(setEmail)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md 
                         shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 
                         focus:border-blue-500"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label 
                htmlFor="password" 
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={handleInputChange(setPassword)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md 
                         shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 
                         focus:border-blue-500"
                placeholder="••••••••"
              />
            </div>
          </div>

          <div className="text-sm text-right">
            <a 
              href="#" 
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Forgot your password?
            </a>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`
              w-full flex justify-center py-2.5 px-4 border border-transparent rounded-md
              shadow-sm text-sm font-medium text-white
              ${isLoading 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
              }
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              transition-colors duration-200
            `}
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Sign in'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;