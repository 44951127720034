// src/components/projects/EditProjectModal.jsx
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { CircularProgress } from '@mui/material';
import Select from 'react-select';
import useAuthStore from '../../store/authStore';
import api from '../../lib/axios';
import { PROJECT_STATUS, PROJECT_STATUS_DISPLAY } from '../../constants/projectConstants';

const EditProjectModal = ({ project, onClose, onSubmit }) => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [error, setError] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  
  const [formData, setFormData] = useState({
    name: project.name,
    description: project.description || '',
    organization: project.organization,
    start_date: format(new Date(project.start_date), 'yyyy-MM-dd'),
    end_date: format(new Date(project.end_date), 'yyyy-MM-dd'),
    status: project.status === 'PAUSED' ? PROJECT_STATUS.ON_HOLD : project.status,
    budget_type: project.budget_type,
    budget_amount: project.budget_amount || '',
    hourly_rate: project.hourly_rate || '',
    is_billable: project.is_billable,
    is_public: project.is_public,
    team_members: project.team_members || []
  });

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await api.get(`/api/employees/${user.employee_id}/team/`);
      const options = response.data.map(employee => ({
        value: employee.id,
        label: `${employee.user.name} (${employee.user.full_title})`
      }));
      setTeamOptions(options);
    } catch (err) {
      console.error('Error fetching team members:', err);
    } finally {
      setLoadingTeam(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await onSubmit(formData);
      onClose();
    } catch (err) {
      setError(err.message || 'Failed to update project');
      setLoading(false);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="w-[40vw] h-[80vh] max-w-none max-h-none p-0 overflow-auto">
        <DialogHeader className="px-6 py-4 border-b">
          <DialogTitle className="text-xl font-semibold text-gray-900">
            Edit Project
          </DialogTitle>
        </DialogHeader>

        <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="px-6 py-4 space-y-6">
              {error && (
                <div className="bg-red-50 text-red-500 p-4 rounded-lg text-sm">
                  {error}
                </div>
              )}

              {/* Project Details Section */}
              <div className="bg-white rounded-lg border border-gray-100 p-6 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Project Name *
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Enter project name"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    rows={3}
                    placeholder="Enter project description"
                  />
                </div>
              </div>

              {/* Timeline Section */}
              <div className="bg-white rounded-lg border border-gray-100 p-6">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Project Timeline
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">Start Date *</label>
                    <input
                      type="date"
                      value={formData.start_date}
                      onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">End Date *</label>
                    <input
                      type="date"
                      value={formData.end_date}
                      onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Project Status Section */}
              <div className="bg-white rounded-lg border border-gray-100 p-6">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Project Settings
                </label>
                <div className="space-y-4">
                  {/* Status Buttons */}
                  <div>
                    <label className="block text-sm text-gray-600 mb-2">Status</label>
                    <div className="flex gap-3">
                      <button
                        type="button"
                        onClick={() => setFormData({ ...formData, status: 'ACTIVE' })}
                        className={`flex-1 py-2 px-4 rounded-lg border-2 transition-colors ${
                          formData.status === 'ACTIVE'
                            ? 'border-green-500 bg-green-50 text-green-700'
                            : 'border-gray-200 hover:border-gray-300 text-gray-600'
                        }`}
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        onClick={() => setFormData({ ...formData, status: 'ON_HOLD' })}
                        className={`flex-1 py-2 px-4 rounded-lg border-2 transition-colors ${
                          formData.status === 'ON_HOLD'
                            ? 'border-yellow-500 bg-yellow-50 text-yellow-700'
                            : 'border-gray-200 hover:border-gray-300 text-gray-600'
                        }`}
                      >
                        On Hold
                      </button>
                    </div>
                  </div>

                  {/* Toggle Switches */}
                  <div className="space-y-3">
                    <label className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                      <span className="text-sm text-gray-700">Billable Project</span>
                      <div 
                        role="checkbox"
                        aria-checked={formData.is_billable}
                        tabIndex={0}
                        onClick={() => setFormData(prev => ({ ...prev, is_billable: !prev.is_billable }))}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            setFormData(prev => ({ ...prev, is_billable: !prev.is_billable }));
                          }
                        }}
                        className={`w-11 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
                          formData.is_billable ? 'bg-blue-500' : 'bg-gray-300'
                        }`}
                      >
                        <div className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
                          formData.is_billable ? 'translate-x-5' : ''
                        }`} />
                      </div>
                    </label>

                    <label className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                      <span className="text-sm text-gray-700">Public Project</span>
                      <div 
                        role="checkbox"
                        aria-checked={formData.is_public}
                        tabIndex={0}
                        onClick={() => setFormData(prev => ({ ...prev, is_public: !prev.is_public }))}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            setFormData(prev => ({ ...prev, is_public: !prev.is_public }));
                          }
                        }}
                        className={`w-11 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
                          formData.is_public ? 'bg-blue-500' : 'bg-gray-300'
                        }`}
                      >
                        <div className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
                          formData.is_public ? 'translate-x-5' : ''
                        }`} />
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              {/* Budget Section */}
              <div className="bg-white rounded-lg border border-gray-100 p-6">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Budget Information
                </label>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-gray-600 mb-2">Budget Type</label>
                    <div className="flex gap-3">
                      <button
                        type="button"
                        onClick={() => setFormData({
                          ...formData,
                          budget_type: 'HOURLY',
                          budget_amount: '',
                          hourly_rate: ''
                        })}
                        className={`flex-1 py-2 px-4 rounded-lg border-2 transition-colors ${
                          formData.budget_type === 'HOURLY'
                            ? 'border-blue-500 bg-blue-50 text-blue-700'
                            : 'border-gray-200 hover:border-gray-300 text-gray-600'
                        }`}
                      >
                        Hourly Rate
                      </button>
                      <button
                        type="button"
                        onClick={() => setFormData({
                          ...formData,
                          budget_type: 'FIXED',
                          budget_amount: '',
                          hourly_rate: ''
                        })}
                        className={`flex-1 py-2 px-4 rounded-lg border-2 transition-colors ${
                          formData.budget_type === 'FIXED'
                            ? 'border-blue-500 bg-blue-50 text-blue-700'
                            : 'border-gray-200 hover:border-gray-300 text-gray-600'
                        }`}
                      >
                        Fixed Budget
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">
                      {formData.budget_type === 'HOURLY' ? 'Hourly Rate ($) *' : 'Budget Amount ($) *'}
                    </label>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      value={formData.budget_type === 'HOURLY' ? formData.hourly_rate : formData.budget_amount}
                      onChange={(e) => setFormData({
                        ...formData,
                        [formData.budget_type === 'HOURLY' ? 'hourly_rate' : 'budget_amount']: e.target.value
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Team Members Section */}
              <div className="bg-white rounded-lg border border-gray-100 p-6">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Team Members
                </label>
                {loadingTeam ? (
                  <div className="flex justify-center p-4">
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <Select
                    isMulti
                    options={teamOptions}
                    value={teamOptions.filter(option => 
                      formData.team_members.includes(option.value)
                    )}
                    onChange={(selected) => setFormData({
                      ...formData,
                      team_members: selected ? selected.map(item => item.value) : []
                    })}
                    className="w-full"
                    classNamePrefix="select"
                    placeholder="Select team members..."
                  />
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="px-6 py-4 border-t flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed min-w-[100px] flex items-center justify-center"
                disabled={loading}
              >
                {loading ? <CircularProgress size={20} color="inherit" /> : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditProjectModal;