// src/components/dashboard/AppUsageSection.jsx
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ViewToggle from './ViewToggle';
import VisTimelineView from './VisTimelineView';

const AppUsageSection = ({ appUsage, timeline, workDay }) => {
  const [view, setView] = useState('table');

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const calculatePercentage = (timeSpent, totalTime) => {
    return ((timeSpent / (totalTime * 60)) * 100).toFixed(1);
  };

  return (
    <div className="space-y-4">
      {/* Header with view toggle */}
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">
          Application Usage
        </h2>
        <ViewToggle view={view} onViewChange={setView} />
      </div>

      {/* Table View */}
      {view === 'table' && (
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Application</TableCell>
                <TableCell>Category</TableCell>
                <TableCell align="right">Time Spent</TableCell>
                <TableCell align="right">Percentage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appUsage.map((app) => (
                <TableRow key={app.name}>
                  <TableCell component="th" scope="row">
                    {app.name}
                  </TableCell>
                  <TableCell>{app.category}</TableCell>
                  <TableCell align="right">
                    {formatTime(app.timeSpent)}
                  </TableCell>
                  <TableCell align="right">
                    {calculatePercentage(app.timeSpent, workDay.stats.hoursToday)}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {appUsage.length > 0 && (
            <div className="p-4 border-t border-gray-200">
              <p className="text-sm text-gray-600">
                Note: Percentages may sum to more than 100% due to applications running simultaneously.
              </p>
            </div>
          )}
        </TableContainer>
      )}

      {/* Timeline View */}
      {view === 'timeline' && (
        <VisTimelineView 
          timeline={timeline} 
          workDay={workDay}
        />
      )}
    </div>
  );
};

export default AppUsageSection;