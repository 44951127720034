// src/api/timeTracking.js
import api from '../lib/axios';

export const timeTrackingApi = {
  // Get all sessions with optional filters
  getSessions: (filters = {}, employeeId) => {
    return api.get('/api/sessions/', { 
      params: {
        is_online: false, // Only get completed sessions
        ordering: 'start_time', // Order by start time
        employee: employeeId, // Filter by employee ID
        ...filters
      }
    });
  },

  // Get sessions for date range
  getSessionsByDateRange: (startDate, endDate, employeeId) => {
    return timeTrackingApi.getSessions({
      start_time__gte: `${startDate}T00:00:00`,
      start_time__lte: `${endDate}T23:59:59`
    }, employeeId);
  },

  // Get projects
  getProjects: () => {
    return api.get('/api/projects/');
  },

  // Get tasks for a specific project
  getTasks: (filters = {}) => {
    return api.get('/api/tasks/', { 
      params: filters 
    });
  }
};

// Helper to format time from ISO string to 24h format
export const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString('en-GB', { 
    hour: '2-digit', 
    minute: '2-digit',
    hour12: false 
  });
};

// Generate a random color for projects
export const getProjectColor = (projectId) => {
  const colors = [
    '#3B82F6', '#10B981', '#8B5CF6', '#F59E0B', 
    '#EF4444', '#EC4899', '#6366F1', '#14B8A6'
  ];
  
  if (!projectId) return '#94A3B8';
  
  const index = Math.abs(projectId.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0)) % colors.length;
  
  return colors[index];
};