import React from 'react'
import { Menu } from 'lucide-react'
import useAuthStore from '../../store/authStore'

const NavBar = ({ onMenuClick }) => {
  const { user, logout } = useAuthStore()

  const handleLogout = () => {
    logout()
  }

  return (
    <nav className="bg-white border-b border-gray-200 fixed top-0 right-0 left-0 z-10">
      <div className="flex items-center justify-between h-16 px-4">
        {/* Left side - Menu button */}
        <div className="flex items-center">
          <button
            onClick={onMenuClick}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            <Menu className="h-6 w-6" />
          </button>
          <h1 className="ml-4 text-xl font-semibold text-gray-800">TREKKO</h1>
        </div>

        {/* Right side - User menu */}
        <div className="flex items-center gap-4">
          <span className="text-gray-600">{user?.email}</span>
          <button
            onClick={handleLogout}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  )
}

export default NavBar