import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import api from '../../lib/axios';
import useAuthStore from '../../store/authStore';

const DEFAULT_PASSWORD = "DefaultPass123!@#";
const ROLES = ['EMPLOYEE', 'MANAGER'];

const AddUserModal = ({ onClose, onUserAdded }) => {
  const { user: currentUser } = useAuthStore();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    department_id: '',
    position_id: '',
    role: 'EMPLOYEE',  // Default role
    manager_id: '',
  });

  // Separate state for field-specific errors
  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    email: '',
    department_id: '',
    position_id: '',
  });

  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  // Fetch departments and potential managers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [deptsResponse, managersResponse] = await Promise.all([
          api.get('/api/departments/'),
          api.get('/api/employees/')
        ]);

        setDepartments(deptsResponse.data);
        // Get all employees who can be managers (role is MANAGER)
        setManagers(managersResponse.data.filter(emp => 
          emp.user?.role === 'MANAGER'
        ));
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setGeneralError('Failed to load form data');
      }
    };
    fetchData();
  }, []);

  // Fetch positions when department changes
  useEffect(() => {
    const fetchPositions = async () => {
      if (!formData.department_id) {
        setPositions([]);
        return;
      }

      try {
        const response = await api.get('/api/positions/');
        // Filter positions for selected department
        setPositions(response.data.filter(
          pos => pos.department === formData.department_id
        ));
      } catch (err) {
        console.error('Failed to fetch positions:', err);
        setGeneralError('Failed to load positions');
      }
    };

    fetchPositions();
  }, [formData.department_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear error when field is modified
    setFieldErrors(prev => ({
      ...prev,
      [name]: ''
    }));

    // Reset position when department changes
    if (name === 'department_id') {
      setFormData(prev => ({
        ...prev,
        position_id: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setFieldErrors({});
    setLoading(true);

    try {
      // First create the user
      await api.post('/api/auth/signup/', {
        email: formData.email,
        password: DEFAULT_PASSWORD,
        confirm_password: DEFAULT_PASSWORD,
        name: formData.name,
        role: formData.role,
        organization_id: currentUser.organization.id,
        department_id: formData.department_id,
        position_id: formData.position_id
      });

      // If a manager was selected, assign them
      if (formData.manager_id) {
        const employeeResponse = await api.get('/api/employees/');
        const newEmployee = employeeResponse.data.find(
          emp => emp.user.email === formData.email
        );

        if (newEmployee) {
          await api.post(`/api/employees/${newEmployee.id}/assign_manager/`, {
            manager_id: formData.manager_id
          });
        }
      }

      onUserAdded();
      onClose();
    } catch (err) {
      console.error('Error:', err);
      if (err.response?.data?.error) {
        const errorData = err.response.data.error;
        const newFieldErrors = {};
        
        Object.entries(errorData).forEach(([field, errors]) => {
          if (Array.isArray(errors)) {
            newFieldErrors[field] = errors[0];
          } else {
            newFieldErrors[field] = errors;
          }
        });
        
        setFieldErrors(newFieldErrors);
      } else {
        setGeneralError(err.response?.data?.message || 'Failed to create user');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New User</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className="space-y-4">
          {generalError && (
            <div className="text-red-500 text-sm mb-4">
              {generalError}
            </div>
          )}

          <TextField
            autoFocus
            name="name"
            label="Full Name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
          />

          <TextField
            name="email"
            label="Email Address"
            fullWidth
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={!!fieldErrors.email}
            helperText={fieldErrors.email}
          />

          <FormControl fullWidth required>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={formData.role}
              onChange={handleChange}
              label="Role"
            >
              {ROLES.map(role => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl 
            fullWidth 
            required
            error={!!fieldErrors.department_id}
          >
            <InputLabel>Department</InputLabel>
            <Select
              name="department_id"
              value={formData.department_id}
              onChange={handleChange}
              label="Department"
            >
              {departments.map(dept => (
                <MenuItem key={dept.id} value={dept.id}>
                  {dept.name}
                </MenuItem>
              ))}
            </Select>
            {fieldErrors.department_id && (
              <div className="text-red-500 text-sm mt-1">
                {fieldErrors.department_id}
              </div>
            )}
          </FormControl>

          <FormControl 
            fullWidth 
            required
            error={!!fieldErrors.position_id}
          >
            <InputLabel>Position</InputLabel>
            <Select
              name="position_id"
              value={formData.position_id}
              onChange={handleChange}
              label="Position"
              disabled={!formData.department_id}
            >
              {positions.map(pos => (
                <MenuItem key={pos.id} value={pos.id}>
                  {pos.title}
                </MenuItem>
              ))}
            </Select>
            {fieldErrors.position_id && (
              <div className="text-red-500 text-sm mt-1">
                {fieldErrors.position_id}
              </div>
            )}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Manager</InputLabel>
            <Select
              name="manager_id"
              value={formData.manager_id}
              onChange={handleChange}
              label="Manager"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {managers.map(manager => (
                <MenuItem key={manager.id} value={manager.id}>
                  {manager.user.name} ({manager.user.department?.name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions className="p-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Add User'
            )}
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserModal;