// src/store/authStore.js
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import api from '../lib/axios';

const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      employeeId: null,
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
      loginError: null,
      requiresPasswordChange: false,

      hasRole: (role) => {
        const user = get().user;
        return user?.role === role || false;
      },

      isManager: () => {
        const user = get().user;
        return user?.role === 'MANAGER' || false;
      },

      isAdmin: () => {
        const user = get().user;
        return user?.role === 'ADMIN' || false;
      },

      getOrganization: () => {
        const user = get().user;
        return user?.organization || null;
      },

      login: async (email, password) => {
        try {
          const response = await api.post('/api/auth/login/', { email, password });
          const { user, access, refresh, requires_password_change } = response.data;
          
          api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
          
          set({
            user,
            employeeId: user.employee_id,
            accessToken: access,
            refreshToken: refresh,
            isAuthenticated: true,
            requiresPasswordChange: requires_password_change,
            loginError: null
          });
      
          console.log('Auth state updated successfully');
          return true;
        } catch (error) {
          console.error('Login error:', error);
          set({ 
            user: null,
            employeeId: null,
            accessToken: null,
            refreshToken: null,
            isAuthenticated: false,
            loginError: error.response?.data?.message || 'Failed to login'
          });
          throw error;
        }
      },

      changePassword: async (currentPassword, newPassword, confirmPassword) => {
        try {
          const response = await api.post('/api/auth/change-password/', {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
          });

          const { access, refresh, user } = response.data;
          api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
          
          set({
            accessToken: access,
            refreshToken: refresh,
            user,
            requiresPasswordChange: false
          });

          return true;
        } catch (error) {
          console.error('Password change error:', error);
          throw error;
        }
      },

      logout: () => {
        delete api.defaults.headers.common['Authorization'];
        set({
          user: null,
          employeeId: null,
          accessToken: null,
          refreshToken: null,
          isAuthenticated: false,
          loginError: null,
          requiresPasswordChange: false
        });
      },

      clearLoginError: () => {
        set({ loginError: null });
      },

      updateUser: (userData) => {
        console.log('Updating user data:', userData);
        set({
          user: { ...userData },
        });
      },
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;