import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Users, 
  Clock, 
  Activity, 
  AlertCircle,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Chip
} from '@mui/material';
import api from '../../lib/axios';
import useAuthStore from '../../store/authStore';
import { generatePersonalStatsHistory } from '../../utils/statsSimulation';

const TeamDashboard = () => {
  const { user, employeeId } = useAuthStore();
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberStats, setTeamMemberStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(false);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        setLoading(true);
        const teamResponse = await api.get(`/api/employees/${employeeId}/team/`);
        const fetchedTeamMembers = teamResponse.data;
        setTeamMembers(fetchedTeamMembers);

        const detailedStats = fetchedTeamMembers.map(member => {
          const history = generatePersonalStatsHistory(member.user.id);
          return {
            ...member,
            currentStats: history[0]
          };
        });

        setTeamMemberStats(detailedStats);
      } catch (err) {
        console.error('Error fetching team data:', err);
        setError('Failed to load team statistics');
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchTeamData();
    }
  }, [user?.id]);

  const calculateTeamStats = () => {
    const activeMembers = teamMemberStats.filter(member => member.currentStats.worked);
    const totalHours = activeMembers.reduce((sum, member) => 
      sum + (member.currentStats.stats?.hoursToday || 0), 0
    );
    const avgProductivity = activeMembers.reduce((sum, member) => 
      sum + (member.currentStats.stats?.productivity || 0), 0
    ) / (activeMembers.length || 1);
    const totalAlerts = activeMembers.reduce((sum, member) => 
      sum + (member.currentStats.stats?.alerts || 0), 0
    );

    return {
      totalHours: Number(totalHours.toFixed(1)),
      avgProductivity: Math.round(avgProductivity),
      activeMembers: activeMembers.length,
      totalAlerts
    };
  };

  const StatCard = ({ id, title, value, icon: Icon, color }) => {
    const isExpanded = expandedPanel === id;

    const getStatusColor = (isWorking) => {
      return isWorking ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800';
    };

    const getStatusText = (isWorking) => {
      return isWorking ? 'Active' : 'Inactive';
    };

    const getValue = (member) => {
      if (!member.currentStats.worked) return '-';

      switch(id) {
        case 'hours':
          return `${member.currentStats.stats.hoursToday}h`;
        case 'productivity':
          return `${member.currentStats.stats.productivity}%`;
        case 'apps':
          return member.currentStats.stats.activeApps;
        case 'alerts':
          return member.currentStats.stats.alerts;
        default:
          return '-';
      }
    };

    const sortedMembers = [...teamMemberStats].sort((a, b) => {
      if (id === 'alerts' && a.currentStats.worked && b.currentStats.worked) {
        return b.currentStats.stats.alerts - a.currentStats.stats.alerts;
      }
      return 0;
    });

    return (
      <Accordion 
        expanded={isExpanded}
        onChange={() => setExpandedPanel(isExpanded ? false : id)}
        sx={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          border: '1px solid #e5e7eb',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{
            '&:hover': {
              backgroundColor: '#f9fafb',
            },
          }}
        >
          <div className="flex items-center justify-between w-full">
            <div>
              <p className="text-sm font-medium text-gray-600">{title}</p>
              <p className="mt-2 text-3xl font-semibold text-gray-900">{value}</p>
            </div>
            <div className="flex items-center gap-4">
              <div className={`p-3 rounded-full ${color}`}>
                <Icon className="w-6 h-6 text-white" />
              </div>
              {isExpanded ? 
                <ChevronUp className="w-5 h-5 text-gray-500" /> : 
                <ChevronDown className="w-5 h-5 text-gray-500" />
              }
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMembers.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell component="th" scope="row">
                      <Link 
                        to={id === 'alerts' ? `/employee/${member.id}/alerts` : `/employee/${member.id}`}
                        className="text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {member.user.name}
                      </Link>
                    </TableCell>
                    <TableCell>{member.user.department?.name || '-'}</TableCell>
                    <TableCell align="right">
                      {getValue(member)}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        label={getStatusText(member.currentStats.worked)}
                        className={getStatusColor(member.currentStats.worked)}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  const teamStats = calculateTeamStats();

  return (
    <div className="space-y-6">
      {/* Header */}
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">Team Overview</h1>
        <p className="mt-1 text-sm text-gray-600">
          Monitor your team's performance and activity
        </p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 gap-4">
        <StatCard
          id="hours"
          title="Team Hours Today"
          value={`${teamStats.totalHours}h`}
          icon={Clock}
          color="bg-blue-500"
        />
        
        <StatCard
          id="productivity"
          title="Average Productivity"
          value={`${teamStats.avgProductivity}%`}
          icon={Activity}
          color="bg-green-500"
        />

        <StatCard
          id="apps"
          title="Active Members"
          value={teamStats.activeMembers}
          icon={Users}
          color="bg-purple-500"
        />

        <StatCard
          id="alerts"
          title="Total Alerts"
          value={teamStats.totalAlerts}
          icon={AlertCircle}
          color="bg-red-500"
        />
      </div>

      {/* Additional Team Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Recent Activity</h2>
          <div className="mt-4 text-center text-gray-500">
            Coming soon...
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Productivity Trends
          </h2>
          <div className="mt-4 text-center text-gray-500">
            Coming soon...
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDashboard;