import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import TimeEntry from './TimeEntry';
import { timeTrackingApi, getProjectColor, formatTime } from '../../api/timeTracking';
import useAuthStore from '../../store/authStore';

const DailyView = ({ date, onAddTime, minimal = false }) => {
  const { employeeId } = useAuthStore(); // Get employeeId directly from store
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const fetchSessions = async () => {
      if (!employeeId) {
        setError('No employee ID found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const dateStr = format(date, 'yyyy-MM-dd');
        const response = await timeTrackingApi.getSessions({
          start_time__gte: `${dateStr}T00:00:00`,
          start_time__lte: `${dateStr}T23:59:59`
        }, employeeId);

        setSessions(response.data);
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError('Failed to load time entries');
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };

    fetchSessions();
  }, [date, employeeId]);

  // Group sessions by project
  const entriesByProject = sessions.reduce((acc, session) => {
    const projectId = session.project || 'unassigned';
    if (!acc[projectId]) {
      acc[projectId] = {
        project: {
          id: session.project,
          name: session.project_name || 'No Project',
          color: getProjectColor(session.project)
        },
        entries: []
      };
    }
    acc[projectId].entries.push(session);
    return acc;
  }, {});

  // Calculate total time from all sessions
  const totalMinutes = sessions.reduce((sum, session) => {
    if (session.start_time && session.end_time) {
      const startTime = new Date(session.start_time);
      const endTime = new Date(session.end_time);
      return sum + Math.floor((endTime - startTime) / (1000 * 60));
    }
    return sum;
  }, 0);

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  if (loading && !initialized) {
    return (
      <div className="flex justify-center items-center p-8">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center">
        {error}
      </div>
    );
  }

  if (!loading && sessions.length === 0) {
    return (
      <div className="divide-y divide-gray-200">
        <div className="p-4 bg-gray-50">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-500">Total Time</span>
            <span className="text-lg font-semibold text-gray-900">0:00</span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center py-12">
          <p className="text-gray-500 text-lg mb-4">No sessions logged today</p>
          <button
            onClick={() => onAddTime()}
            className="px-4 py-2 text-sm text-blue-600 hover:bg-blue-50 rounded-lg border border-blue-200"
          >
            + Add time entry
          </button>
        </div>
      </div>
    );
  }

  // Rest of the component remains the same...
  return (
    <div className="divide-y divide-gray-200">
      {/* Header with total time */}
      <div className="p-4 bg-gray-50">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium text-gray-500">Total Time</span>
          <span className="text-lg font-semibold text-gray-900">
            {formatDuration(totalMinutes)}
          </span>
        </div>
      </div>

      {minimal ? (
        // Minimal view for calendar summary
        <div className="p-4">
          {Object.entries(entriesByProject).map(([projectId, { project, entries }]) => {
            const projectDuration = entries.reduce((sum, entry) => {
              if (entry.start_time && entry.end_time) {
                const startTime = new Date(entry.start_time);
                const endTime = new Date(entry.end_time);
                return sum + Math.floor((endTime - startTime) / (1000 * 60));
              }
              return sum;
            }, 0);

            return (
              <div key={projectId} className="mb-4 last:mb-0">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: project.color }}
                    />
                    <span className="font-medium text-gray-900">{project.name}</span>
                  </div>
                  <span className="text-sm text-gray-600">
                    {formatDuration(projectDuration)}
                  </span>
                </div>
                {/* Task summaries */}
                {Array.from(new Set(entries.map(entry => entry.task_name)))
                  .filter(Boolean)
                  .map(taskName => (
                    <div key={taskName} className="ml-5 text-sm text-gray-500">
                      • {taskName}
                    </div>
                  ))
                }
              </div>
            );
          })}
        </div>
      ) : (
        // Full view for daily page
        <div className="divide-y divide-gray-200">
          {Object.entries(entriesByProject).map(([projectId, { project, entries }]) => {
            const projectDuration = entries.reduce((sum, entry) => {
              if (entry.start_time && entry.end_time) {
                const startTime = new Date(entry.start_time);
                const endTime = new Date(entry.end_time);
                return sum + Math.floor((endTime - startTime) / (1000 * 60));
              }
              return sum;
            }, 0);

            return (
              <div key={projectId} className="divide-y divide-gray-100">
                {/* Project header */}
                <div className="flex justify-between items-center p-4 bg-white">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: project.color }}
                    />
                    <span className="font-medium text-gray-900">{project.name}</span>
                  </div>
                  <span className="text-sm text-gray-600">
                    {formatDuration(projectDuration)}
                  </span>
                </div>
  
                {/* Project entries */}
                <div className="bg-white">
                  {entries.map(entry => (
                    <TimeEntry
                      key={entry.id}
                      entry={{
                        ...entry,
                        startTime: formatTime(entry.start_time),
                        endTime: formatTime(entry.end_time),
                        task_name: entry.task_name,
                        duration: Math.floor(
                          (new Date(entry.end_time) - new Date(entry.start_time)) / (1000 * 60)
                        )
                      }}
                      onEdit={() => onAddTime(project.id, entry.task)}
                    />
                  ))}
                  <button
                    onClick={() => onAddTime(projectId === 'unassigned' ? null : projectId)}
                    className="w-full p-2 text-sm text-gray-500 hover:bg-gray-50 text-left"
                  >
                    + Add time entry
                  </button>
                </div>
              </div>
            );
          })}
  
          {/* Add entry for new project */}
          <div className="p-4 bg-white">
            <button
              onClick={() => onAddTime()}
              className="w-full py-2 px-4 text-sm text-blue-600 hover:bg-blue-50 rounded-lg border border-blue-200"
            >
              + Add time for another project
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyView;