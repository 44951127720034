// src/components/time-tracking/TimeEntry.jsx
import React from 'react';
import { MoreVertical, Clock } from 'lucide-react';

const TimeEntry = ({ entry, onEdit }) => {
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  return (
    <div className="px-4 py-3 hover:bg-gray-50 flex items-center justify-between group">
      <div className="flex items-center gap-3">
        <div className="text-gray-400">
          <Clock className="w-4 h-4" />
        </div>
        <div>
          <div className="font-medium text-gray-900">
            {entry.startTime} - {formatDuration(entry.duration)}
          </div>
          {entry.task && (
            <div className="text-sm text-gray-500">
              {entry.task.name}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        {entry.isManual && (
          <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
            Manual
          </span>
        )}
        <button
          onClick={onEdit}
          className="p-1 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <MoreVertical className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default TimeEntry;