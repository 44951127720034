// src/components/time-tracking/WeeklyView.jsx
import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, parseISO } from 'date-fns';
import { CircularProgress } from '@mui/material';
import { timeTrackingApi, getProjectColor } from '../../api/timeTracking';
import useAuthStore from '../../store/authStore';

const WeeklyView = ({ date, onAddTime }) => {
  const { employeeId } = useAuthStore();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  // Get week dates
  const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Start from Monday
  const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
  const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd });

  useEffect(() => {
    const fetchWeekSessions = async () => {
      if (!employeeId) {
        setError('No employee ID found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Format dates for API
        const startDate = format(weekStart, 'yyyy-MM-dd');
        const endDate = format(weekEnd, 'yyyy-MM-dd');
        
        // Fetch sessions for the week
        const response = await timeTrackingApi.getSessionsByDateRange(
          startDate,
          endDate,
          employeeId
        );

        setSessions(response.data);
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError('Failed to load time entries');
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };

    fetchWeekSessions();
  }, [date, employeeId]);

  // Group sessions by project and task
  const groupedEntries = sessions.reduce((projects, session) => {
    const projectId = session.project || 'unassigned';
    const taskId = session.task || 'unassigned';
    const projectTaskKey = `${projectId}-${taskId}`;

    if (!projects[projectTaskKey]) {
      projects[projectTaskKey] = {
        project: {
          id: session.project,
          name: session.project_name || 'No Project',
          color: getProjectColor(session.project)
        },
        task: {
          id: session.task,
          name: session.task_name || 'No Task'
        },
        days: {}
      };
    }

    // Group sessions by date
    const dateKey = format(parseISO(session.start_time), 'yyyy-MM-dd');
    if (!projects[projectTaskKey].days[dateKey]) {
      projects[projectTaskKey].days[dateKey] = {
        duration: 0,
        entries: []
      };
    }

    const duration = session.duration || 0;
    projects[projectTaskKey].days[dateKey].duration += duration;
    projects[projectTaskKey].days[dateKey].entries.push(session);

    return projects;
  }, {});

  const getDayTotal = (date) => {
    const dateKey = format(date, 'yyyy-MM-dd');
    return Object.values(groupedEntries).reduce((total, group) => {
      return total + (group.days[dateKey]?.duration || 0);
    }, 0);
  };

  const getTimeDisplay = (minutes) => {
    if (!minutes) return '-';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  if (loading && !initialized) {
    return (
      <div className="flex justify-center items-center p-8">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-4 text-left text-sm font-medium text-gray-500 w-64">
              Project & Task
            </th>
            {weekDays.map(day => (
              <th 
                key={day.toISOString()} 
                className="p-4 text-sm font-medium text-gray-500 min-w-[120px]"
              >
                <div>{format(day, 'EEE')}</div>
                <div className="text-xs text-gray-400">
                  {format(day, 'MMM d')}
                </div>
              </th>
            ))}
            <th className="p-4 text-left text-sm font-medium text-gray-500">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {Object.keys(groupedEntries).length > 0 ? (
            // Show actual entries if they exist
            Object.entries(groupedEntries).map(([key, { project, task, days }]) => (
              <tr key={key} className="group hover:bg-gray-50">
                <td className="p-4">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full flex-shrink-0"
                      style={{ backgroundColor: project.color }}
                    />
                    <div>
                      <div className="font-medium text-gray-900">{project.name}</div>
                      <div className="text-sm text-gray-500">{task.name}</div>
                    </div>
                  </div>
                </td>
                {weekDays.map(day => {
                  const dateKey = format(day, 'yyyy-MM-dd');
                  return (
                    <td
                      key={dateKey}
                      className="p-4 text-center cursor-pointer hover:bg-gray-100"
                      onClick={() => onAddTime(project.id, task.id, day)}
                    >
                      <div className="text-sm text-gray-900">
                        {getTimeDisplay(days[dateKey]?.duration)}
                      </div>
                    </td>
                  );
                })}
                <td className="p-4 font-medium text-gray-900">
                  {getTimeDisplay(
                    Object.values(days).reduce((sum, day) => sum + day.duration, 0)
                  )}
                </td>
              </tr>
            ))
          ) : (
            // Show placeholder row when no entries exist
            <tr className="group hover:bg-gray-50">
              <td className="p-4">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full flex-shrink-0 bg-gray-200" />
                  <div className="text-sm text-gray-400 italic">
                    Click to add time entry
                  </div>
                </div>
              </td>
              {weekDays.map(day => (
                <td
                  key={day.toISOString()}
                  className="p-4 text-center cursor-pointer hover:bg-gray-100"
                  onClick={() => onAddTime(null, null, day)}
                >
                  <div className="text-sm text-gray-400">-</div>
                </td>
              ))}
              <td className="p-4 text-gray-400">-</td>
            </tr>
          )}
          
          {/* Totals row - always show this */}
          <tr className="bg-gray-50 font-medium">
            <td className="p-4 text-gray-900">Daily Total</td>
            {weekDays.map(day => (
              <td key={day.toISOString()} className="p-4 text-center text-gray-900">
                {getTimeDisplay(getDayTotal(day))}
              </td>
            ))}
            <td className="p-4 text-gray-900">
              {getTimeDisplay(
                weekDays.reduce(
                  (total, day) => total + getDayTotal(day),
                  0
                )
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyView;