// src/pages/projects/Projects.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import ProjectsPage from '../../components/projects/ProjectsPage';
import useAuthStore from '../../store/authStore';
import { CircularProgress } from '@mui/material';

const Projects = () => {
  const { user, isAuthenticated, hasRole } = useAuthStore();
  const isManager = hasRole('MANAGER');

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    return (
      <div className="h-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return <ProjectsPage isManager={isManager} />;
};

export default Projects;