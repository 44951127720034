// src/components/projects/ProjectsPage.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, DollarSign } from 'lucide-react';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Card, CardContent } from '../../components/ui/card';
import ProjectDetailsModal from './ProjectDetailsModal';
import AddProjectModal from './AddProjectModal';
import EditProjectModal from './EditProjectModal';
import api from '../../lib/axios';
import { PROJECT_STATUS, PROJECT_STATUS_DISPLAY } from '../../constants/projectConstants';

const ProjectsPage = ({ isManager }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [showAddProject, setShowAddProject] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/projects/');
      setProjects(response.data);
    } catch (err) {
      setError('Failed to load projects');
      console.error('Error fetching projects:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProject = async (projectData) => {
    try {
      await api.post('/api/projects/', projectData);
      await fetchProjects();
      setShowAddProject(false);
    } catch (err) {
      console.error('Error adding project:', err);
      throw err;
    }
  };

  const handleUpdateProject = async (projectId, updatedData) => {
    try {
      await api.put(`/api/projects/${projectId}/`, updatedData);
      await fetchProjects();
      setEditingProject(null);
    } catch (err) {
      console.error('Error updating project:', err);
      throw err;
    }
  };

  const handleDeleteProject = async (projectId) => {
    if (!window.confirm('Are you sure you want to delete this project?')) return;
    
    try {
      await api.delete(`/api/projects/${projectId}/`);
      await fetchProjects();
    } catch (err) {
      console.error('Error deleting project:', err);
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Projects</h1>
          <p className="mt-1 text-sm text-gray-600">
            View and manage your project assignments
          </p>
        </div>
        {isManager && (
          <button
            onClick={() => setShowAddProject(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <Plus className="w-4 h-4" />
            New Project
          </button>
        )}
      </div>

      {/* Projects Table */}
      <Card>
        <CardContent className="p-0">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Project Name</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>Team Members</TableHead>
                <TableHead>Budget Type</TableHead>
                {isManager && <TableHead className="text-right">Actions</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>
                    <button
                      className="text-blue-600 hover:underline font-medium"
                      onClick={() => setSelectedProject(project)}
                    >
                      {project.name}
                    </button>
                  </TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      project.status === PROJECT_STATUS.ACTIVE || project.status === 'ACTIVE'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {project.status === 'PAUSED' ? 'On Hold' : 'Active'}
                    </span>
                  </TableCell>
                  <TableCell>{format(new Date(project.start_date), 'MMM d, yyyy')}</TableCell>
                  <TableCell>{format(new Date(project.end_date), 'MMM d, yyyy')}</TableCell>
                  <TableCell>{project.team_members_count} members</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <DollarSign className="w-4 h-4 text-gray-500" />
                      <span className="text-sm">
                        {project.budget_type === 'HOURLY' 
                          ? `$${project.hourly_rate}/hr`
                          : `$${project.budget_amount}`
                        }
                      </span>
                    </div>
                  </TableCell>
                  {isManager && (
                    <TableCell>
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => setEditingProject(project)}
                          className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteProject(project.id)}
                          className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* Project Details Modal */}
      {selectedProject && (
        <ProjectDetailsModal
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
          onEdit={() => {
            setEditingProject(selectedProject);
            setSelectedProject(null);
          }}
          isManager={isManager}
        />
      )}

      {/* Add Project Modal */}
      {showAddProject && (
        <AddProjectModal
          onClose={() => setShowAddProject(false)}
          onSubmit={handleAddProject}
        />
      )}

      {/* Edit Project Modal */}
      {editingProject && (
        <EditProjectModal
          project={editingProject}
          onClose={() => setEditingProject(null)}
          onSubmit={(data) => handleUpdateProject(editingProject.id, data)}
        />
      )}
    </div>
  );
};

export default ProjectsPage;