// src/components/projects/ProjectDetailsModal.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';
import { CircularProgress } from '@mui/material';
import { Calendar, Clock, DollarSign, Users, Edit2, X } from 'lucide-react';
import { format } from 'date-fns';
import TaskList from '../tasks/TaskList';
import AddTaskModal from '../tasks/AddTaskModal';
import TaskDetailsModal from '../tasks/TaskDetailsModal';
import EditTaskModal from '../tasks/EditTaskModal';
import api from '../../lib/axios';
import useAuthStore from '../../store/authStore';
import { PROJECT_STATUS, PROJECT_STATUS_DISPLAY } from '../../constants/projectConstants';

const ProjectDetailsModal = ({ project, isManager, onClose, onEdit }) => {
  console.log('ProjectDetailsModal rendered with project:', project);

  const [activityData, setActivityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editingTask, setEditingTask] = useState(null);
  const [teamMembersDetails, setTeamMembersDetails] = useState([]);
  const { employeeId } = useAuthStore();
  const [refreshTasks, setRefreshTasks] = useState(0);

  const fetchTeamMembers = useCallback(async () => {
    console.log('1. fetchTeamMembers started');
    console.log('2. Project team_members:', project?.team_members);

    if (!project?.team_members?.length) {
      console.log('3. No team members found in project');
      setTeamMembersDetails([]);
      return;
    }

    try {
      console.log('4. Current user employee ID:', employeeId);
      
      console.log('5. Starting to fetch team members from manager endpoint');
      const response = await api.get(`/api/employees/${employeeId}/team/`);
      console.log('6. Team members response:', response.data);

      // Filter and map team members to match project.team_members
      const membersDetails = response.data
        .filter(member => project.team_members.includes(member.id))
        .map(member => ({
          id: member.id,
          value: member.id,
          name: member.user.name,
          email: member.user.email,
          role: member.user.role,
          department: member.department.name,
          position: member.position.title,
          label: `${member.user.name} (${member.position.title} - ${member.department.name})`
        }));

      console.log('7. Processed team members details:', membersDetails);
      setTeamMembersDetails(membersDetails);
      
    } catch (err) {
      console.error('8. Error in fetchTeamMembers:', err);
      console.error('9. Error response:', err.response?.data);
      setError('Failed to load team members');
    }
  }, [project?.team_members, employeeId]);
  

  const fetchActivitySummary = useCallback(async () => {
    console.log('10. fetchActivitySummary started');
    if (!project?.id) {
      console.log('11. No project ID available');
      return;
    }
    
    try {
      const response = await api.get(`/api/projects/${project.id}/activity_summary/`);
      console.log('12. Activity summary response:', response.data);
      setActivityData(response.data);
    } catch (err) {
      console.error('13. Error fetching activity summary:', err);
      setError('Failed to load activity data');
    } finally {
      setLoading(false);
    }
  }, [project?.id]);

  useEffect(() => {
    console.log('14. useEffect triggered with project:', project);
    
    if (project) {
      console.log('15. Starting data fetching...');
      Promise.all([
        fetchActivitySummary(),
        fetchTeamMembers()
      ]).then(() => {
        console.log('16. All data fetched successfully');
      }).catch(err => {
        console.error('17. Error in Promise.all:', err);
      });
    }
  }, [project, fetchActivitySummary, fetchTeamMembers]);

  const handleTaskSuccess = () => {
    if (selectedTask) setSelectedTask(null);
    if (editingTask) setEditingTask(null);
    // Increment refresh counter to trigger task list reload
    setRefreshTasks(prev => prev + 1);
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="w-[75vw] h-[90vh] max-w-none max-h-none p-0 overflow-hidden">
        <div className="relative h-full flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center px-6 py-4 border-b shrink-0">
            <h2 className="text-xl font-semibold text-gray-900">Project Details</h2>
            <div className="flex items-center gap-4">
              {isManager && (
                <button
                  onClick={onEdit}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  <Edit2 className="w-4 h-4" />
                  Edit Project
                </button>
              )}
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Tabs */}
          <Tabs defaultValue="overview" className="flex-1 flex flex-col h-[calc(100%-65px)] overflow-hidden">
            <TabsList className="px-6 border-b shrink-0">
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="tasks">Tasks</TabsTrigger>
              <TabsTrigger value="activity">Activity</TabsTrigger>
            </TabsList>

            <div className="flex-1 overflow-hidden">
              <TabsContent value="overview" className="p-6 h-full overflow-y-auto">
                <div className="space-y-6">
                  {/* Project Description */}
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900">{project.name}</h2>
                    <p className="mt-2 text-gray-600">{project.description}</p>
                  </div>

                  {/* Status and Settings */}
                  <div className="flex gap-3">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                      project.status === PROJECT_STATUS.ACTIVE || project.status === 'ACTIVE'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {project.status === 'PAUSED' ? 'On Hold' : project.status}
                    </span>
                    {project.is_billable && (
                      <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                        Billable
                      </span>
                    )}
                    {project.is_public && (
                      <span className="px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                        Public
                      </span>
                    )}
                  </div>

                  {/* Project Details Grid */}
                  <div className="grid grid-cols-2 gap-6">
                    <div className="space-y-4">
                      {/* Dates */}
                      <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                        <div className="flex items-start gap-3">
                          <Calendar className="w-5 h-5 text-gray-500 mt-1" />
                          <div>
                            <p className="text-sm text-gray-500">Start Date</p>
                            <p className="font-medium">
                              {format(new Date(project.start_date), 'MMMM d, yyyy')}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-start gap-3">
                          <Calendar className="w-5 h-5 text-gray-500 mt-1" />
                          <div>
                            <p className="text-sm text-gray-500">End Date</p>
                            <p className="font-medium">
                              {format(new Date(project.end_date), 'MMMM d, yyyy')}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Budget */}
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="flex items-start gap-3">
                          <DollarSign className="w-5 h-5 text-gray-500 mt-1" />
                          <div>
                            <p className="text-sm text-gray-500">Budget</p>
                            <p className="font-medium">
                              {project.budget_type === 'HOURLY' 
                                ? `Hourly Rate: $${project.hourly_rate}/hour`
                                : `Fixed Budget: $${project.budget_amount}`
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Team Members */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-sm font-medium text-gray-700">Team Members</h3>
                        <span className="text-sm text-gray-500">
                          {teamMembersDetails.length} members
                        </span>
                      </div>
                      <div className="space-y-3">
                        {loading ? (
                          <div className="flex justify-center py-4">
                            <CircularProgress size={24} />
                          </div>
                        ) : teamMembersDetails.length > 0 ? (
                          teamMembersDetails.map((member) => (
                            <div key={member.id} className="flex items-center gap-3">
                              <Users className="w-5 h-5 text-gray-500" />
                              <div>
                                <p className="text-sm font-medium text-gray-900">
                                  {member.name}
                                </p>
                                <p className="text-xs text-gray-500">
                                  {member.position} - {member.department}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-sm text-gray-500 text-center py-2">
                            No team members assigned
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="tasks" className="h-full overflow-hidden">
                <div className="p-6 h-full overflow-hidden">
                  <TaskList
                    projectId={project.id}
                    isManager={isManager}
                    onAddTask={() => setShowAddTask(true)}
                    onViewTask={setSelectedTask}
                    onEditTask={setEditingTask}
                    teamMembers={teamMembersDetails}
                    refreshTrigger={refreshTasks}
                  />
                </div>
              </TabsContent>

              <TabsContent value="activity" className="p-6 h-full overflow-y-auto">
                {loading ? (
                  <div className="flex justify-center p-8">
                    <CircularProgress />
                  </div>
                ) : error ? (
                  <div className="text-red-500 text-center p-4">{error}</div>
                ) : activityData && (
                  <div className="space-y-6">
                    {/* Activity Summary Stats */}
                    <div className="grid grid-cols-4 gap-4">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="text-sm text-gray-500">Total Time</p>
                        <p className="text-xl font-semibold">{activityData.total_time}h</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="text-sm text-gray-500">Active Time</p>
                        <p className="text-xl font-semibold">{activityData.active_time}h</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="text-sm text-gray-500">Average Activity</p>
                        <p className="text-xl font-semibold">{activityData.avg_activity}%</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="text-sm text-gray-500">Total Sessions</p>
                        <p className="text-xl font-semibold">{activityData.total_sessions}</p>
                      </div>
                    </div>

                    {/* Team Activity */}
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 mb-4">Team Activity</h3>
                      <div className="space-y-3">
                        {Object.entries(activityData.team_activity || {}).map(([id, member]) => (
                          <div key={id} className="bg-gray-50 p-4 rounded-lg">
                            <div className="flex items-center justify-between mb-2">
                              <span className="font-medium">{member.name}</span>
                              <span className="text-sm text-gray-500">
                                {member.avg_activity}% Active
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-4 text-sm">
                              <div className="flex items-center gap-2">
                                <Clock className="w-4 h-4 text-gray-500" />
                                <span>{member.total_time}h Total</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <Clock className="w-4 h-4 text-gray-500" />
                                <span>{member.active_time}h Active</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </DialogContent>

      {/* Task-related Modals */}
      {showAddTask && (
        <AddTaskModal
          projectId={project.id}
          teamMembers={teamMembersDetails}
          onClose={() => setShowAddTask(false)}
          onSuccess={handleTaskSuccess}
        />
      )}

      {selectedTask && (
        <TaskDetailsModal
        task={selectedTask}
        isManager={isManager}
        onClose={() => setSelectedTask(null)}
        onEdit={() => {
          setEditingTask(selectedTask);
          setSelectedTask(null);
        }}
      />
    )}

    {editingTask && (
      <EditTaskModal
        task={editingTask}
        projectId={project.id}
        teamMembers={teamMembersDetails}
        onClose={() => setEditingTask(null)}
        onSuccess={handleTaskSuccess}
      />
    )}
  </Dialog>
);
};

export default ProjectDetailsModal;