// src/utils/statsSimulation.js
import seedrandom from 'seedrandom';
import { getStatsImages } from './imageUtils';

const WORK_DAY_CONFIG = {
  START_HOUR: 9,  // 9 AM
  END_HOUR: 17,   // 5 PM
  DURATION: 8,    // 8 hours
  BREAK_TIME: 60  // 60 minutes
};

const POSSIBLE_APPS = [
  { name: 'Visual Studio Code', category: 'Development' },
  { name: 'Chrome', category: 'Browser' },
  { name: 'Slack', category: 'Communication' },
  { name: 'Microsoft Teams', category: 'Communication' },
  { name: 'Terminal', category: 'Development' },
  { name: 'Microsoft Word', category: 'Office' },
  { name: 'Microsoft Excel', category: 'Office' },
  { name: 'Postman', category: 'Development' },
  { name: 'Zoom', category: 'Communication' },
  { name: 'Figma', category: 'Design' }
];

// Create deterministic random data based on ID and date
const generateDeterministicValue = (id, date, min, max, seed = '') => {
  // Convert date to consistent format regardless of timezone
  const dateStr = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  const seedString = `${id}-${dateStr}-${seed}`;
  const rng = seedrandom(seedString);
  return min + rng() * (max - min);
};

// Generate timeline intervals for an app
const generateAppTimelineIntervals = (employeeId, date, appIndex, workStart, workEnd) => {
  const intervals = [];
  let currentTime = new Date(workStart);
  
  // Generate 1-3 sessions for this app
  const sessionCount = Math.floor(generateDeterministicValue(
    employeeId, 
    date, 
    1, 
    4, 
    `sessions-${appIndex}`
  ));

  for (let i = 0; i < sessionCount; i++) {
    // Random start time for this session
    if (i > 0) {
      // For subsequent sessions, ensure some gap from the last session
      const lastEnd = new Date(intervals[intervals.length - 1].end);
      const minGap = 15; // 15 minutes minimum gap
      const maxGap = 60; // 1 hour maximum gap
      const gapMinutes = Math.floor(generateDeterministicValue(
        employeeId,
        date,
        minGap,
        maxGap,
        `gap-${appIndex}-${i}`
      ));
      currentTime = new Date(lastEnd.getTime() + gapMinutes * 60000);
    }

    // Session duration between 30 minutes and 3 hours
    const sessionDuration = Math.floor(generateDeterministicValue(
      employeeId,
      date,
      30,
      180,
      `duration-${appIndex}-${i}`
    ));

    const sessionEnd = new Date(currentTime.getTime() + sessionDuration * 60000);
    
    // Ensure we don't exceed work end time
    if (sessionEnd > workEnd) {
      if (currentTime < workEnd) {
        intervals.push({
          start: currentTime.toISOString(),
          end: workEnd.toISOString()
        });
      }
      break;
    }

    intervals.push({
      start: currentTime.toISOString(),
      end: sessionEnd.toISOString()
    });

    currentTime = sessionEnd;
  }

  return intervals;
};

// Calculate total time from intervals considering overlaps
const calculateTotalTime = (intervals) => {
  if (!intervals.length) return 0;
  
  // Sort all time points
  const timePoints = intervals.flatMap(interval => [
    { time: new Date(interval.start), type: 'start' },
    { time: new Date(interval.end), type: 'end' }
  ]).sort((a, b) => a.time - b.time);

  let totalMinutes = 0;
  let activeApps = 0;
  let lastTime = timePoints[0].time;

  // Process each time point
  timePoints.forEach(point => {
    if (activeApps > 0) {
      // Add time since last point
      totalMinutes += (point.time - lastTime) / (1000 * 60);
    }
    
    // Update active apps count
    if (point.type === 'start') activeApps++;
    else activeApps--;
    
    lastTime = point.time;
  });

  return totalMinutes;
};

// Generate app usage with timeline data
const generateDailyAppUsage = (employeeId, date) => {
  const workStart = new Date(date);
  workStart.setHours(WORK_DAY_CONFIG.START_HOUR, 0, 0, 0);
  
  const workEnd = new Date(date);
  workEnd.setHours(WORK_DAY_CONFIG.END_HOUR, 0, 0, 0);

  // Select 4-7 apps for the day
  const numApps = Math.floor(generateDeterministicValue(employeeId, date, 4, 8, 'numApps'));
  const selectedApps = [...POSSIBLE_APPS]
    .sort(() => generateDeterministicValue(employeeId, date, 0, 1, 'appSort') - 0.5)
    .slice(0, numApps);

  // Generate timeline data for each app
  const timelineData = selectedApps.map((app, index) => {
    const intervals = generateAppTimelineIntervals(
      employeeId,
      date,
      index,
      workStart,
      workEnd
    );

    const totalMinutes = intervals.reduce((total, interval) => 
      total + (new Date(interval.end) - new Date(interval.start)) / (1000 * 60),
      0
    );

    return {
      app,
      intervals,
      totalMinutes
    };
  });

  // Calculate total worked time considering overlaps
  const allIntervals = timelineData.flatMap(data => data.intervals);
  const actualTotalMinutes = calculateTotalTime(allIntervals);

  return {
    actualMinutes: actualTotalMinutes,
    apps: timelineData.map(({ app, totalMinutes }) => ({
      name: app.name,
      category: app.category,
      timeSpent: Math.round(totalMinutes)
    })).sort((a, b) => b.timeSpent - a.timeSpent),
    timeline: timelineData.map(({ app, intervals }) => ({
      name: app.name,
      category: app.category,
      intervals
    }))
  };
};

// Generate monitoring data for a specific day
const generateDailyMonitoring = (employeeId, date, appUsage) => {
  if (!appUsage.length) return { headshots: [], screenshots: [] };
  return getStatsImages();
};

// Generate stats for a specific day
const generateDailyStats = (employeeId, date) => {
  const appData = generateDailyAppUsage(employeeId, date);
  
  if (!appData.apps.length) {
    return {
      date: date.toISOString(),
      worked: true,
      stats: null,
      appUsage: [],
      timeline: [],
      monitoring: { headshots: [], screenshots: [] }
    };
  }

  // Use actual worked time (considering overlaps)
  const totalHours = (appData.actualMinutes / 60).toFixed(1);

  const startTime = new Date(date);
  startTime.setHours(WORK_DAY_CONFIG.START_HOUR, 0, 0);
  
  const endTime = new Date(startTime);
  endTime.setHours(startTime.getHours() + WORK_DAY_CONFIG.DURATION);

  const stats = {
    hoursToday: Number(totalHours),
    productivity: Math.floor(generateDeterministicValue(employeeId, date, 75, 95, 'productivity')),
    activeApps: appData.apps.length,
    alerts: 4,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    remainingBreak: WORK_DAY_CONFIG.BREAK_TIME - Math.floor(generateDeterministicValue(employeeId, date, 0, 30, 'breakUsed'))
  };

  return {
    date: date.toISOString(),
    worked: true,
    stats,
    appUsage: appData.apps,
    timeline: appData.timeline,
    monitoring: generateDailyMonitoring(employeeId, date, appData.apps)
  };
};

// Generate stats for the last 3 days
export const generatePersonalStatsHistory = (employeeId, currentDate = new Date()) => {
  console.log('Generating history for:', { employeeId, currentDate });
  const history = [];
  
  for (let i = 0; i < 3; i++) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - i);
    date.setHours(0, 0, 0, 0); // Start of day
    
    const stats = generateDailyStats(employeeId, date);
    console.log(`Day ${i} stats:`, stats);
    history.push(stats);
  }

  return history;
};

// Get current day's stats (convenience function)
export const generatePersonalStats = (employeeId) => {
  const history = generatePersonalStatsHistory(employeeId);
  return history[0];
};

// Generate team stats based on individual stats
export const generateTeamStats = (managerId, teamSize) => {
  const date = new Date();
  // Generate number of working members (60-90% of team size)
  const workingMembersCount = Math.floor(generateDeterministicValue(
    managerId, 
    date, 
    teamSize * 0.6, 
    teamSize * 0.9, 
    'workingCount'
  ));

  // Generate individual alerts for each working member
  let totalAlerts = 0;
  for (let i = 0; i < workingMembersCount; i++) {
    const memberAlerts = Math.floor(generateDeterministicValue(
      `${managerId}-member-${i}`,
      date,
      0,
      3,  // Maximum 3 alerts per person
      'alerts'
    ));
    totalAlerts += memberAlerts;
  }

  return {
    totalHours: Number((workingMembersCount * generateDeterministicValue(managerId, date, 7, 9, 'teamHours')).toFixed(1)),
    avgProductivity: Math.round(generateDeterministicValue(managerId, date, 80, 90, 'teamProductivity')),
    activeMembers: workingMembersCount,
    totalAlerts
  };
};