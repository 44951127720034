import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import api from '../../lib/axios';

const ManagerReassignModal = ({ 
  open, 
  onClose, 
  deletingManager, // This is the employee record of the manager being deleted
  onComplete 
}) => {
  const [managers, setManagers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch available managers and team members
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError('');

        // Fetch all employees to get available managers
        const managersResponse = await api.get('/api/employees/');
        
        // Get team members of the manager being deleted
        const teamResponse = await api.get(`/api/employees/${deletingManager.id}/team/`);

        // Filter for managers, excluding the one being deleted
        const availableManagers = managersResponse.data.filter(emp => 
          emp.user?.role === 'MANAGER' && 
          emp.id !== deletingManager.id
        );

        setManagers(availableManagers);
        setTeamMembers(teamResponse.data);

        // Initialize assignments state
        const initialAssignments = teamResponse.data.reduce((acc, member) => {
          acc[member.id] = '';  // Empty string means no manager assigned
          return acc;
        }, {});
        setAssignments(initialAssignments);

      } catch (err) {
        console.error('Failed to fetch data:', err);
        setError('Failed to load team data');
      } finally {
        setLoading(false);
      }
    };

    if (open && deletingManager) {
      fetchData();
    }
  }, [open, deletingManager]);

  const handleAssignmentChange = (employeeId, managerId) => {
    setAssignments(prev => ({
      ...prev,
      [employeeId]: managerId
    }));
  };

  const handleRemoveAllManagers = async () => {
    try {
      setLoading(true);
      setError('');

      // Update each team member to have no manager
      await Promise.all(
        teamMembers.map(member =>
          api.post(`/api/employees/${member.id}/assign_manager/`, {
            manager_id: null
          })
        )
      );

      onComplete();
    } catch (err) {
      console.error('Error removing managers:', err);
      setError('Failed to remove manager assignments');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAssignments = async () => {
    try {
      setLoading(true);
      setError('');

      // Update each team member with their new manager
      await Promise.all(
        Object.entries(assignments).map(([employeeId, managerId]) =>
          api.post(`/api/employees/${employeeId}/assign_manager/`, {
            manager_id: managerId || null
          })
        )
      );

      onComplete();
    } catch (err) {
      console.error('Error updating assignments:', err);
      setError('Failed to update manager assignments');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Reassign Team Members</DialogTitle>
      <DialogContent>
        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}
        
        <div className="mb-4">
          <p className="text-gray-600">
            {deletingManager?.user?.name} is currently managing {teamMembers.length} team members. 
            Please reassign them to new managers or remove their manager assignments.
          </p>
        </div>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Member</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>New Manager</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.user?.name}</TableCell>
                  <TableCell>{member.user?.department?.name}</TableCell>
                  <TableCell>{member.user?.position?.title}</TableCell>
                  <TableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={assignments[member.id] || ''}
                        onChange={(e) => handleAssignmentChange(member.id, e.target.value)}
                        disabled={loading}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {managers.map((manager) => (
                          <MenuItem key={manager.id} value={manager.id}>
                            {manager.user.name} ({manager.user.department?.name})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions className="p-4">
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          onClick={handleRemoveAllManagers}
          className="px-4 py-2 text-yellow-700 hover:bg-yellow-50 rounded-lg"
          disabled={loading}
        >
          Remove All Managers
        </button>
        <button
          onClick={handleSaveAssignments}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            'Save Assignments'
          )}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagerReassignModal;