// src/components/layout/Sidebar.jsx
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { 
  Layout, 
  Users, 
  Settings,
  BarChart,
  Clock,
  ChevronLeft,
  ChevronRight,
  FolderKanban, // Add this import for Projects icon
} from 'lucide-react'
import useAuthStore from '../../store/authStore'
import { ROLES } from '../../types/roles'

const SideBar = ({ isOpen, onToggle }) => {
  const location = useLocation()
  const { hasRole } = useAuthStore()
  const isManager = hasRole('MANAGER')
  
  const menuItems = [
    // Always visible - Personal dashboard
    {
      title: 'My Dashboard',
      icon: <Layout className="w-5 h-5" />,
      path: '/dashboard'
    },
    // Manager only - Team view
    ...(isManager ? [{
      title: 'Team Overview',
      icon: <Users className="w-5 h-5" />,
      path: '/team'
    }] : []),
    // Common items
    {
      title: 'Projects',  // Add Projects menu item
      icon: <FolderKanban className="w-5 h-5" />,
      path: '/projects'
    },
    {
      title: 'Time Tracking',
      icon: <Clock className="w-5 h-5" />,
      path: '/time-tracking'
    },
    {
      title: 'Analytics',
      icon: <BarChart className="w-5 h-5" />,
      path: '/analytics'
    },
    {
      title: 'Settings',
      icon: <Settings className="w-5 h-5" />,
      path: '/settings'
    }
  ]

  return (
    <aside 
      className={`
        ${isOpen ? 'w-64' : 'w-20'} 
        bg-white border-r border-gray-200 
        transition-all duration-300 ease-in-out
        h-screen fixed left-0 top-0
        flex flex-col justify-between
      `}
    >
      <div className="flex flex-col">
        {/* Logo/Brand */}
        <div className="h-16 flex items-center justify-between px-4 border-b border-gray-200">
          {isOpen && <span className="font-semibold text-xl">TREKKO</span>}
          <button
            onClick={onToggle}
            className="p-2 rounded-lg hover:bg-gray-100 ml-auto"
          >
            {isOpen ? 
              <ChevronLeft className="w-5 h-5" /> : 
              <ChevronRight className="w-5 h-5" />
            }
          </button>
        </div>

        {/* Menu Items */}
        <div className="flex-1 py-6">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex items-center px-4 py-3 mx-2 rounded-lg
                ${location.pathname === item.path 
                  ? 'bg-gray-100 text-blue-600' 
                  : 'text-gray-600 hover:bg-gray-50'
                }
                ${!isOpen && 'justify-center'}
              `}
            >
              {item.icon}
              {isOpen && (
                <span className="ml-3 text-sm font-medium">
                  {item.title}
                </span>
              )}
            </Link>
          ))}
        </div>
      </div>
    </aside>
  )
}

export default SideBar