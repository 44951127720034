// src/components/time-tracking/MonthlyView.jsx
import React, { useState, useEffect } from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isToday,
  startOfWeek,
  endOfWeek,
  parseISO,
  isAfter
} from 'date-fns';
import { Plus, X } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { CircularProgress } from '@mui/material';
import DailyView from './DailyView';
import { timeTrackingApi } from '../../api/timeTracking';
import useAuthStore from '../../store/authStore';

const MonthlyView = ({ date, onAddTime, onDateChange }) => {
  const { employeeId } = useAuthStore();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showDailySummary, setShowDailySummary] = useState(false);
  
  // Get the calendar date range
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 });
  const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 1 });
  const calendarDays = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  useEffect(() => {
    const fetchMonthSessions = async () => {
      if (!employeeId) {
        setError('No employee ID found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Format dates for API
        const startDate = format(calendarStart, 'yyyy-MM-dd');
        const endDate = format(calendarEnd, 'yyyy-MM-dd');
        
        // Fetch sessions for the month
        const response = await timeTrackingApi.getSessionsByDateRange(
          startDate,
          endDate,
          employeeId
        );

        setSessions(response.data);
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError('Failed to load time entries');
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };

    fetchMonthSessions();
  }, [date, employeeId]);

  // Calculate total duration for each day
  const getDailyTotals = () => {
    return sessions.reduce((totals, session) => {
      const dateKey = format(parseISO(session.start_time), 'yyyy-MM-dd');
      if (!totals[dateKey]) {
        totals[dateKey] = 0;
      }
      
      totals[dateKey] += session.duration || 0;
      return totals;
    }, {});
  };

  const getTimeDisplay = (minutes) => {
    if (!minutes) return null;
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  const handleCloseSummary = () => {
    setShowDailySummary(false);
    setSelectedDay(null);
  };

  const handleDayClick = (day, isPlusClick, isCurrentMonth) => {
    if (!isCurrentMonth) return;
    
    const today = new Date();
    if (isAfter(day, today)) return; // Prevent actions on future dates

    if (isPlusClick) {
      onDateChange(day);
      onAddTime(null, null, day);
    } else {
      const dateKey = format(day, 'yyyy-MM-dd');
      const timeTotal = getDailyTotals()[dateKey];
      if (timeTotal > 0) {
        setSelectedDay(day);
        setShowDailySummary(true);
      }
    }
  };

  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const dailyTotals = getDailyTotals();

  if (loading && !initialized) {
    return (
      <div className="flex justify-center items-center p-8">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white">
      {/* Calendar grid */}
      <div className="grid grid-cols-7 bg-gray-50">
        {weekDays.map(day => (
          <div
            key={day}
            className="py-2 text-center text-sm font-medium text-gray-500"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-px bg-gray-200">
        {calendarDays.map(day => {
          const dateKey = format(day, 'yyyy-MM-dd');
          const timeTotal = dailyTotals[dateKey];
          const timeDisplay = getTimeDisplay(timeTotal);
          const isCurrentMonth = isSameMonth(day, date);
          const isFutureDate = isAfter(day, new Date());

          return (
            <div
              key={day.toISOString()}
              className={`
                relative min-h-[120px] bg-white group
                ${!isCurrentMonth ? 'bg-gray-50' : ''}
                ${isToday(day) ? 'border-2 border-blue-500' : ''}
                ${isFutureDate ? 'bg-gray-100 cursor-not-allowed' : ''}
              `}
            >
              {/* Day Header with Date */}
              <div className="p-2">
                <div className={`
                  text-sm font-medium mb-1
                  ${!isCurrentMonth ? 'text-gray-400' : 'text-gray-900'}
                  ${isToday(day) ? 'text-blue-600' : ''}
                `}>
                  {format(day, 'd')}
                </div>
                {isCurrentMonth && !isFutureDate && timeDisplay && (
                  <div className="inline-flex px-2 py-1 rounded-full text-xs bg-blue-50 text-blue-700">
                    {timeDisplay}
                  </div>
                )}
              </div>

              {/* Add Time Button */}
              {isCurrentMonth && !isFutureDate && (
                <div className="absolute top-2 right-2 z-20">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDayClick(day, true, isCurrentMonth);
                    }}
                    className="w-6 h-6 flex items-center justify-center bg-blue-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-blue-600"
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
              )}

              {/* Clickable area for showing summary */}
              {isCurrentMonth && !isFutureDate && (
                <div
                  className="absolute inset-0 top-10 z-10 cursor-pointer"
                  onClick={() => handleDayClick(day, false, isCurrentMonth)}
                />
              )}
            </div>
          );
        })}
      </div>

      {/* Daily Summary Dialog */}
      {showDailySummary && selectedDay && (
        <Dialog 
          open={showDailySummary} 
          onOpenChange={handleCloseSummary}
        >
          <DialogContent className="sm:max-w-2xl">
            <DialogHeader>
              <DialogTitle>
                Time Summary for {format(selectedDay, 'MMMM d, yyyy')}
              </DialogTitle>
              <button
                onClick={handleCloseSummary}
                className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-100"
              >
                <X className="w-4 h-4" />
              </button>
            </DialogHeader>
            <DailyView
              date={selectedDay}
              onAddTime={onAddTime}
              minimal={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default MonthlyView;