// src/components/time-tracking/ViewToggle.jsx
import React from 'react';

const ViewToggle = ({ view, onViewChange, options }) => {
  return (
    <div className="inline-flex rounded-lg border border-gray-200 bg-white">
      {options.map(({ value, label, icon: Icon }) => (
        <button
          key={value}
          onClick={() => onViewChange(value)}
          className={`
            inline-flex items-center px-4 py-2 gap-2
            ${value === options[0].value ? 'rounded-l-lg' : ''}
            ${value === options[options.length - 1].value ? 'rounded-r-lg' : ''}
            ${view === value 
              ? 'bg-blue-50 text-blue-600 border-blue-100' 
              : 'text-gray-600 hover:bg-gray-50'
            }
            ${value !== options[0].value ? 'border-l border-gray-200' : ''}
          `}
        >
          <Icon className="w-4 h-4" />
          <span className="text-sm font-medium">{label}</span>
        </button>
      ))}
    </div>
  );
};

export default ViewToggle;