// App.jsx
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'
import AdminLayout from './components/layout/AdminLayout'
import Login from './pages/auth/Login'
import ChangePassword from './pages/auth/ChangePassword';
import PersonalDashboard from './pages/dashboard/PersonalDashboard'
import TeamDashboard from './pages/dashboard/TeamDashboard'
import AdminDashboard from './pages/admin/AdminDashboard'
import Projects from './pages/projects/Projects'
import useAuthStore from './store/authStore'
import TimeTracking from './pages/time-tracking/TimeTracking';
import { ROLES } from './types/roles'
import EmployeeStats from './pages/dashboard/EmployeeStats'
import AlertsPage from './pages/alerts/AlertsPage';

const App = () => {
  const { isAuthenticated, hasRole, requiresPasswordChange } = useAuthStore()
  const isAdmin = hasRole('ADMIN')  // Changed from ROLES.ADMINISTRATOR

  // Route guards
  const ManagerRoute = ({ element }) => {
    if (isAdmin) return <Navigate to="/admin/dashboard" replace />
    return hasRole('MANAGER') ? element : <Navigate to="/dashboard" replace />
  }

  // Regular user route guard
  const UserRoute = ({ element }) => {
    if (isAdmin) return <Navigate to="/admin/dashboard" replace />
    if (requiresPasswordChange) return <Navigate to="/change-password" replace />
    return isAuthenticated ? element : <Navigate to="/login" replace />
  }

  // If not authenticated, only allow login
  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  // If password change is required, only allow change-password route
  if (requiresPasswordChange) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="*" element={<Navigate to="/change-password" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  // If user is admin, only allow admin routes
  if (isAdmin) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<AdminLayout />}>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            {/* Add other admin routes here */}
          </Route>
          <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  // Regular user routes
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={<UserRoute element={<PersonalDashboard />} />} />
          <Route path="/team" element={<ManagerRoute element={<TeamDashboard />} />} />
          <Route path="/employee/:employeeId" element={<ManagerRoute element={<EmployeeStats />} />} />
          <Route path="/alerts" element={<UserRoute element={<AlertsPage />} />} />
          <Route path="/employee/:employeeId/alerts" element={<ManagerRoute element={<AlertsPage />} />} />
          <Route path="/time-tracking" element={<UserRoute element={<TimeTracking />} />} />
          <Route path="/projects" element={<UserRoute element={<Projects />} />} />
          <Route path="/analytics" element={<UserRoute element={<div>Analytics (Coming Soon)</div>} />} />
          <Route path="/settings" element={<UserRoute element={<div>Settings (Coming Soon)</div>} />} />
        </Route>
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App