import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box,
  CircularProgress,
  FormHelperText
} from '@mui/material';
import { timeTrackingApi } from '../../api/timeTracking';

const ProjectTaskSelect = ({ 
  selectedProject, 
  selectedTask, 
  onProjectChange, 
  onTaskChange 
}) => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState({
    projects: false,
    tasks: false
  });
  const [error, setError] = useState({
    projects: '',
    tasks: ''
  });

  // Fetch projects on mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(prev => ({ ...prev, projects: true }));
        const response = await timeTrackingApi.getProjects();
        setProjects(response.data);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError(prev => ({
          ...prev,
          projects: 'Failed to load projects'
        }));
      } finally {
        setLoading(prev => ({ ...prev, projects: false }));
      }
    };

    fetchProjects();
  }, []);

  // Fetch tasks when project changes
  useEffect(() => {
    const fetchTasks = async () => {
      if (!selectedProject) {
        setTasks([]);
        return;
      }

      try {
        setLoading(prev => ({ ...prev, tasks: true }));
        const response = await timeTrackingApi.getTasks({
          project: selectedProject
        });
        setTasks(response.data);
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError(prev => ({
          ...prev,
          tasks: 'Failed to load tasks'
        }));
      } finally {
        setLoading(prev => ({ ...prev, tasks: false }));
      }
    };

    fetchTasks();
  }, [selectedProject]);

  return (
    <Box sx={{ my: 2 }}>
      <FormControl 
        fullWidth 
        margin="normal"
        error={!!error.projects}
      >
        <InputLabel>Project</InputLabel>
        <Select
          value={selectedProject || ''}
          onChange={(e) => {
            onProjectChange(e.target.value || null);
            onTaskChange(null);
          }}
          label="Project"
          disabled={loading.projects}
        >
          <MenuItem value="">
            <em>No Project</em>
          </MenuItem>
          {projects.map(project => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
        {loading.projects && (
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        )}
        {error.projects && (
          <FormHelperText error>{error.projects}</FormHelperText>
        )}
      </FormControl>

      {selectedProject && (
        <FormControl 
          fullWidth 
          margin="normal"
          error={!!error.tasks}
        >
          <InputLabel>Task</InputLabel>
          <Select
            value={selectedTask || ''}
            onChange={(e) => onTaskChange(e.target.value || null)}
            label="Task"
            disabled={loading.tasks}
          >
            <MenuItem value="">
              <em>No Task</em>
            </MenuItem>
            {tasks.map(task => (
              <MenuItem key={task.id} value={task.id}>
                {task.name}
              </MenuItem>
            ))}
          </Select>
          {loading.tasks && (
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={20} />
            </Box>
          )}
          {error.tasks && (
            <FormHelperText error>{error.tasks}</FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default ProjectTaskSelect;