import React, { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuthStore from '../../store/authStore'
import Navbar from './NavBar'
import Sidebar from './Sidebar'

const MainLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const { isAuthenticated } = useAuthStore()

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <Sidebar 
        isOpen={sidebarOpen} 
        onToggle={toggleSidebar}
      />

      {/* Main Content */}
      <div className="flex flex-col flex-1">
        <Navbar onMenuClick={toggleSidebar} />
        
        {/* Page Content */}
        <main 
          className={`
            flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 
            transition-all duration-300 ease-in-out
            ${sidebarOpen ? 'ml-64' : 'ml-20'}
            mt-16 p-4 md:p-6
          `}
        >
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default MainLayout