import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Search, UserPlus, Edit2, Trash2, Filter, FilterX, X } from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import api from '../../lib/axios';
import { AddUserModal, EditUserModal, ManagerReassignModal } from '../../components/admin';
import useAuthStore from '../../store/authStore';

const AdminDashboard = () => {
  // Core Data States
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  
  // UI States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    department: '',
    position: '',
    name: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({
    department: '',
    position: '',
    name: ''
  });
  
  // Modal States
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [deletingManager, setDeletingManager] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  
  // Current user context
  const { user: currentUser } = useAuthStore();
  

  // Fetch all required data
  const fetchData = useCallback(async (searchTerm = '') => {
    try {
      setLoading(true);
      setError(null);
  
      // Construct the filter query string
      const queryParams = new URLSearchParams();
      
      if (searchTerm.trim()) {
        queryParams.append('search', searchTerm.trim());
      }
      
      if (filters.department) {
        queryParams.append('department', filters.department);
      }
      
      if (filters.position) {
        queryParams.append('position', filters.position);
      }
      
      if (filters.name) {
        queryParams.append('name', filters.name);
      }
  
      const queryString = queryParams.toString();
      const endpoint = `/api/employees/${queryString ? `?${queryString}` : ''}`;
  
      const [employeesRes, departmentsRes, positionsRes] = await Promise.all([
        api.get(endpoint),
        api.get('/api/departments/'),
        api.get('/api/positions/')
      ]);
  
      setEmployees(employeesRes.data);
      setDepartments(departmentsRes.data);
      setPositions(positionsRes.data);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.response?.data?.message || 'Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  // Initial data fetch
  useEffect(() => {
    fetchData('');
  }, [fetchData]);

  const handleSearch = () => {
    fetchData(searchQuery);  // Pass current search query
  };
  
  const clearSearch = () => {
    setSearchQuery('');
    fetchData('');  // Clear search results
  };

  // Handle user deletion with manager reassignment
  const handleDeleteUser = async (employeeId) => {
    try {
      const employeeToDelete = employees.find(emp => emp.id === employeeId);
      
      if (!employeeToDelete) {
        throw new Error('Employee not found');
      }

      // Check if user is a manager
      if (employeeToDelete.user?.role === 'MANAGER') {
        const response = await api.get(`/api/employees/${employeeId}/team/`);
        const teamMembers = response.data;

        if (teamMembers.length > 0) {
          setDeletingManager(employeeToDelete);
          setAssignedUsers(teamMembers);
          setShowReassignModal(true);
          return;
        }
      }

      if (!window.confirm('Are you sure you want to delete this user?')) {
        return;
      }

      await api.delete(`/api/employees/${employeeId}/destroy_employee/`);
      await fetchData(); // Refresh the list
      
    } catch (err) {
      console.error('Error in delete operation:', err);
      setError(err.response?.data?.message || 'Failed to delete user');
    }
  };

  //Apply Selected Filters
  const handleApplyFilters = () => {
    setFilters(tempFilters);
    setShowFilters(false);
  };

  const hasActiveFilters = () => {
    return filters.department || filters.position || filters.name;
  };

  // Filter reset handler
  const handleResetFilters = () => {
    setTempFilters({
      department: '',
      position: '',
      name: ''
    });
    setFilters({
      department: '',
      position: '',
      name: ''
    });
  };

  const openFiltersDialog = () => {
    setTempFilters(filters); // Initialize with current filters
    setShowFilters(true);
  };

  // Render loading state
  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 text-lg">{error}</p>
          <Button
            variant="outlined"
            color="primary"
            onClick={fetchData}
            className="mt-4"
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">User Management</h1>
          <p className="mt-1 text-sm text-gray-600">
            {`Managing users for ${currentUser?.organization?.name || 'your organization'}`}
          </p>
        </div>
        <button
          onClick={() => setShowAddModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <UserPlus className="w-4 h-4" />
          Add User
        </button>
      </div>

      {/* Search and Filters */}
      <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
        <div className="flex gap-4">
          <div className="flex-1 relative">
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}>
              <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-20 py-2 border rounded-lg"
              />
            </form>
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            
            {/* Search Actions */}
            <div className="absolute right-2 top-1 flex items-center gap-2">
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="p-1 text-gray-400 hover:text-gray-600"
                  title="Clear search"
                >
                  <X className="w-5 h-5" />
                </button>
              )}
              <button
                onClick={handleSearch}
                className="px-3 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
              >
                Search
              </button>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              className={`
                ${hasActiveFilters() ? 'bg-blue-50 border-blue-500' : ''}
                min-w-[40px] px-2
              `}
              onClick={openFiltersDialog}
            >
              {hasActiveFilters() ? (
                <Filter className="w-5 h-5 text-blue-600 fill-current" />
              ) : (
                <>
                  <Filter className="w-5 h-5" />
                  <span className="ml-2">Filters</span>
                </>
              )}
            </Button>
            
            {hasActiveFilters() && (
              <Button
                variant="outlined"
                color="error"
                className="min-w-[40px] px-2"
                onClick={handleResetFilters}
                title="Clear all filters"
              >
                <FilterX className="w-5 h-5" />
              </Button>
            )}
          </div>
        </div>

        {/* Active Filters Display */}
        {(filters.department || filters.position || filters.name) && (
          <div className="flex gap-2 items-center">
            <span className="text-sm text-gray-500">Active Filters:</span>
            {filters.department && (
              <Chip
                label={`Department: ${departments.find(d => d.id === filters.department)?.name}`}
                onDelete={() => setFilters(f => ({ ...f, department: '' }))}
                size="small"
              />
            )}
            {filters.position && (
              <Chip
                label={`Position: ${positions.find(p => p.id === filters.position)?.title}`}
                onDelete={() => setFilters(f => ({ ...f, position: '' }))}
                size="small"
              />
            )}
            {filters.name && (
              <Chip
                label={`Name: ${filters.name}`}
                onDelete={() => setFilters(f => ({ ...f, name: '' }))}
                size="small"
              />
            )}
          </div>
        )}
      </div>

      {/* Users Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Manager</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>{employee.user?.name || '-'}</TableCell>
                <TableCell>{employee.user?.email || '-'}</TableCell>
                <TableCell>
                  {employee.user?.department?.name ? (
                    <Chip
                      label={employee.user.department.name}
                      size="small"
                      className="bg-gray-100"
                    />
                  ) : '-'}
                </TableCell>
                <TableCell>
                  {employee.user?.position?.title ? (
                    <Chip
                      label={employee.user.position.title}
                      size="small"
                      className="bg-gray-100"
                    />
                  ) : '-'}
                </TableCell>
                <TableCell>
                  <Chip
                    label={employee.user?.role || 'N/A'}
                    size="small"
                    className={`
                      ${employee.user?.role === 'ADMIN' ? 'bg-purple-100 text-purple-800' : ''}
                      ${employee.user?.role === 'MANAGER' ? 'bg-blue-100 text-blue-800' : ''}
                      ${employee.user?.role === 'EMPLOYEE' ? 'bg-green-100 text-green-800' : ''}
                    `}
                  />
                </TableCell>
                <TableCell>
                  {employee.manager?.name || '-'}
                </TableCell>
                <TableCell align="right">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => {
                        setSelectedUser(employee);
                        setShowEditModal(true);
                      }}
                      className="p-1 text-blue-600 hover:bg-blue-50 rounded"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => handleDeleteUser(employee.id)}
                      className="p-1 text-red-600 hover:bg-red-50 rounded"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Filter Dialog */}
      <Dialog
        open={showFilters}
        onClose={() => setShowFilters(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Filter Users</DialogTitle>
        <DialogContent>
          {/* Using state for temporary filter values */}
          <div className="space-y-4 pt-2">
            <FormControl fullWidth>
              <InputLabel id="department-label" className="bg-white px-1">
                Department
              </InputLabel>
              <Select
                labelId="department-label"
                value={tempFilters?.department || ''}
                onChange={(e) => setTempFilters(prev => ({
                  ...prev,
                  department: e.target.value
                }))}
                label="Department"
              >
                <MenuItem value="">All Departments</MenuItem>
                {departments.map((dept) => (
                  <MenuItem key={dept.id} value={dept.id}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="position-label" className="bg-white px-1">
                Position
              </InputLabel>
              <Select
                labelId="position-label"
                value={tempFilters?.position || ''}
                onChange={(e) => setTempFilters(prev => ({
                  ...prev,
                  position: e.target.value
                }))}
                label="Position"
              >
                <MenuItem value="">All Positions</MenuItem>
                {positions.map((pos) => (
                  <MenuItem key={pos.id} value={pos.id}>
                    {pos.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Filter by name"
                value={tempFilters?.name || ''}
                onChange={(e) => setTempFilters(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                variant="outlined"
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="p-4">
          <Button onClick={() => setShowFilters(false)}>Cancel</Button>
          <Button 
            onClick={handleApplyFilters} 
            variant="contained" 
            color="primary"
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modals */}
      {showAddModal && (
        <AddUserModal
          departments={departments}
          positions={positions}
          onClose={() => setShowAddModal(false)}
          onUserAdded={fetchData}
        />
      )}
      
      {showEditModal && selectedUser && (
        <EditUserModal
          employee={selectedUser}
          departments={departments}
          positions={positions}
          onClose={() => {
            setShowEditModal(false);
            setSelectedUser(null);
          }}
          onUserUpdated={fetchData}
        />
      )}

      {showReassignModal && deletingManager && (
        <ManagerReassignModal
          open={showReassignModal}
          onClose={() => {
            setShowReassignModal(false);
            setDeletingManager(null);
            setAssignedUsers([]);
          }}
          deletingManager={deletingManager}
          assignedUsers={assignedUsers}
          onComplete={async () => {
            setShowReassignModal(false);
            setDeletingManager(null);
            setAssignedUsers([]);
            
            try {
              await api.delete(`/api/employees/${deletingManager.id}/destroy_employee/`);
              await fetchData();
            } catch (err) {
              setError('Failed to delete manager after reassignment');
            }
          }}
        />
      )}
    </div>
  );
};

export default AdminDashboard;