// src/components/tasks/TaskDetailsModal.jsx
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import {
  Clock,
  Calendar,
  AlertCircle,
  User,
  Tag,
  BarChart,
  Edit2
} from 'lucide-react';
import Select from 'react-select';
import api from '../../lib/axios';

const ACTIVITY_PERIOD_OPTIONS = [
  { value: '3', label: 'Last 3 Days' },
  { value: '7', label: 'Last 7 Days' },
  { value: '30', label: 'Last 30 Days' }
];

const STATUS_COLORS = {
  TODO: 'bg-blue-100 text-blue-800',
  IN_PROGRESS: 'bg-yellow-100 text-yellow-800',
  COMPLETED: 'bg-green-100 text-green-800'
};

const PRIORITY_COLORS = {
  HIGH: 'bg-red-100 text-red-800',
  MEDIUM: 'bg-yellow-100 text-yellow-800',
  LOW: 'bg-blue-100 text-blue-800'
};

const TaskDetailsModal = ({ task, isManager, onClose, onEdit }) => {
  const [activityData, setActivityData] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchActivityData() {
      if (!task?.id) return;

      try {
        setLoading(true);
        const response = await api.get(`/api/tasks/${task.id}/recent_activity/?days=${selectedPeriod}`);
        setActivityData(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching activity data:', err);
        setError('Failed to load activity data');
      } finally {
        setLoading(false);
      }
    }

    fetchActivityData();
  }, [task?.id, selectedPeriod]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="w-[40vw] h-[80vh] max-w-none max-h-none p-0 overflow-auto">
        <DialogHeader className="px-6 py-4 border-b">
          <div className="flex justify-between items-start">
            <DialogTitle className="text-xl font-semibold text-gray-900">
              Task Details
            </DialogTitle>
            {isManager && (
              <button
                onClick={onEdit}
                className="flex items-center gap-2 px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg"
              >
                <Edit2 className="w-4 h-4" />
                Edit Task
              </button>
            )}
          </div>
        </DialogHeader>

        <div className="px-6 py-4">
          <div className="space-y-6">
            {/* Task Header */}
            <div>
              <h2 className="text-2xl font-semibold text-gray-900">{task.name}</h2>
              <p className="mt-2 text-gray-600">{task.description}</p>
            </div>

            {/* Status and Priority */}
            <div className="flex gap-3">
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${STATUS_COLORS[task.status]}`}>
                {task.status_display}
              </span>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${PRIORITY_COLORS[task.priority]}`}>
                {task.priority_display} Priority
              </span>
              {task.is_billable && (
                <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                  Billable
                </span>
              )}
            </div>

            {/* Task Details Grid */}
            <div className="grid grid-cols-2 gap-6">
              {/* Left Column */}
              <div className="space-y-4">
                {/* Dates */}
                <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                  <div className="flex items-start gap-3">
                    <Calendar className="w-5 h-5 text-gray-500 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Start Date</p>
                      <p className="font-medium">{format(new Date(task.start_date), 'MMMM d, yyyy')}</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Calendar className="w-5 h-5 text-gray-500 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Due Date</p>
                      <div className="flex items-center gap-2">
                        <p className={`font-medium ${task.is_overdue ? 'text-red-600' : ''}`}>
                          {format(new Date(task.due_date), 'MMMM d, yyyy')}
                        </p>
                        {task.is_overdue && (
                          <AlertCircle className="w-4 h-4 text-red-500" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Time Tracking */}
                <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                  <div className="flex items-start gap-3">
                    <Clock className="w-5 h-5 text-gray-500 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Estimated Time</p>
                      <p className="font-medium">{task.estimated_hours} hours</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Clock className="w-5 h-5 text-gray-500 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Time Spent</p>
                      <p className="font-medium">
                        {task.total_time_spent} hours ({task.active_time_spent} hours active)
                      </p>
                    </div>
                  </div>
                </div>

                {/* Assignee */}
                {task.assignee && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start gap-3">
                      <User className="w-5 h-5 text-gray-500 mt-1" />
                      <div>
                        <p className="text-sm text-gray-500">Assigned To</p>
                        <p className="font-medium">{task.assignee_name}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Tags */}
                {task.tags && task.tags.length > 0 && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start gap-3">
                      <Tag className="w-5 h-5 text-gray-500 mt-1" />
                      <div>
                        <p className="text-sm text-gray-500 mb-2">Tags</p>
                        <div className="flex flex-wrap gap-2">
                          {task.tags.map(tag => (
                            <span 
                              key={tag}
                              className="px-2 py-1 bg-white rounded-full text-sm text-gray-600 border border-gray-200"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Right Column - Progress and Activity */}
              <div className="space-y-4">
                {/* Progress */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-sm font-medium text-gray-700">Progress</p>
                    <span className="text-sm text-gray-500">{task.completion_percentage}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                      style={{ width: `${task.completion_percentage}%` }}
                    />
                  </div>
                </div>

                {/* Recent Activity */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <BarChart className="w-5 h-5 text-gray-500" />
                      <p className="text-sm font-medium text-gray-700">Recent Activity</p>
                    </div>
                    <Select
                      value={ACTIVITY_PERIOD_OPTIONS.find(option => option.value === selectedPeriod)}
                      onChange={(selected) => setSelectedPeriod(selected.value)}
                      options={ACTIVITY_PERIOD_OPTIONS}
                      className="w-40"
                      classNamePrefix="select"
                      isSearchable={false}
                    />
                  </div>

                  {loading ? (
                    <div className="flex justify-center py-4">
                      <CircularProgress size={24} />
                    </div>
                  ) : error ? (
                    <div className="text-red-500 text-sm text-center py-4">
                      {error}
                    </div>
                  ) : activityData && (
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-white p-3 rounded-lg">
                        <p className="text-sm text-gray-500">Total Time</p>
                        <p className="text-lg font-semibold">
                          {activityData.total_time || 0}h
                        </p>
                      </div>
                      <div className="bg-white p-3 rounded-lg">
                        <p className="text-sm text-gray-500">Active Time</p>
                        <p className="text-lg font-semibold">
                          {activityData.active_time || 0}h
                        </p>
                      </div>
                      <div className="col-span-2 bg-white p-3 rounded-lg">
                        <p className="text-sm text-gray-500">Average Activity</p>
                        <p className="text-lg font-semibold">
                          {activityData.avg_activity || 0}%
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TaskDetailsModal;