// src/pages/dashboard/PersonalDashboard.jsx
import React, { useState, useEffect } from 'react';
import { 
  Clock, 
  Activity, 
  AlertCircle,
  MonitorPlay,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { 
  CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import MonitoringGallery from '../../components/dashboard/MonitoringGallery';
import AppUsageSection from '../../components/dashboard/AppUsageSection';
import { generatePersonalStatsHistory } from '../../utils/statsSimulation';

const PersonalDashboard = () => {
  const { user } = useAuthStore();
  const [statsHistory, setStatsHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPersonalStats = async () => {
      try {
        setLoading(true);
        
        // Generate simulated stats history
        const history = generatePersonalStatsHistory(user.id);
        setStatsHistory(history);

      } catch (err) {
        console.error('Error generating stats:', err);
        setError('Failed to load personal statistics');
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchPersonalStats();
    }
  }, [user?.id]);

  const selectedDayData = statsHistory.find(day => 
    new Date(day.date).toDateString() === selectedDate.toDateString()
  ) || { worked: false, stats: null, appUsage: [], timeline: [], monitoring: { headshots: [], screenshots: [] } };

  const navigateDate = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + (direction === 'prev' ? -1 : 1));
    setSelectedDate(newDate);
  };

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className="mt-2 text-3xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-3 rounded-full ${color}`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">My Dashboard</h1>
        <p className="mt-1 text-sm text-gray-600">
          Welcome back, {user?.name}
        </p>
      </div>

      {/* Date Navigation */}
      <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm border border-gray-200">
        <button
          onClick={() => navigateDate('prev')}
          className="p-2 hover:bg-gray-100 rounded-full"
          disabled={selectedDate <= new Date(statsHistory[statsHistory.length - 1].date)}
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <span className="text-lg font-medium">
          {selectedDate.toLocaleDateString('en-US', { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
          })}
        </span>
        <button
          onClick={() => navigateDate('next')}
          className="p-2 hover:bg-gray-100 rounded-full"
          disabled={selectedDate >= new Date()}
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      {/* No Activity Message */}
      {!selectedDayData.worked && (
        <div className="bg-white p-8 rounded-lg shadow-sm border border-gray-200 text-center">
          <p className="text-lg text-gray-600">No activity recorded for this day</p>
        </div>
      )}

      {selectedDayData.worked && (
        <>
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <StatCard
              title="Hours Today"
              value={`${selectedDayData.stats.hoursToday}h`}
              icon={Clock}
              color="bg-blue-500"
            />
            <StatCard
              title="Productivity"
              value={`${selectedDayData.stats.productivity}%`}
              icon={Activity}
              color="bg-green-500"
            />
            <StatCard
              title="Active Apps"
              value={selectedDayData.stats.activeApps}
              icon={MonitorPlay}
              color="bg-purple-500"
            />
            <Link to="/alerts">
              <StatCard
                title="Alerts"
                value={selectedDayData.stats.alerts}
                icon={AlertCircle}
                color="bg-red-500"
                className="hover:shadow-md transition-shadow"
              />
            </Link>
          </div>

          {/* App Usage Section with Timeline */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2">
              <AppUsageSection 
                appUsage={selectedDayData.appUsage}
                timeline={selectedDayData.timeline}
                workDay={selectedDayData}
              />
            </div>
          </div>

          {/* Monitoring Galleries */}
          <div className="grid grid-cols-1 gap-4">
            <MonitoringGallery 
              title="Recent Verification Photos"
              images={selectedDayData.monitoring.headshots}
              type="headshot"
            />
            <MonitoringGallery 
              title="Recent Activity Snapshots"
              images={selectedDayData.monitoring.screenshots}
              type="screenshot"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalDashboard;