import React, { useState, useEffect } from 'react';
import {
 Dialog,
 DialogTitle,
 DialogContent,
 DialogActions,
 TextField,
 Select,
 MenuItem,
 FormControl,
 InputLabel,
 CircularProgress,
} from '@mui/material';
import api from '../../lib/axios';
import useAuthStore from '../../store/authStore';

const ROLES = ['EMPLOYEE', 'MANAGER'];

const EditUserModal = ({ employee, onClose, onUserUpdated }) => {
 const { user: currentUser } = useAuthStore();
 
 const [isDataLoaded, setIsDataLoaded] = useState(false);
 const [formData, setFormData] = useState(null);
 const [departments, setDepartments] = useState([]);
 const [positions, setPositions] = useState([]);
 const [managers, setManagers] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState('');
 const [fieldErrors, setFieldErrors] = useState({
   name: '',
   department_id: '',
   position_id: '',
   role: ''
 });

 // Initial data load
 useEffect(() => {
   const fetchInitialData = async () => {
     try {
       setLoading(true);
       const [deptsResponse, managersResponse] = await Promise.all([
         api.get('/api/departments/'),
         api.get('/api/employees/')
       ]);

       const depts = deptsResponse.data;
       const managersList = managersResponse.data.filter(emp => 
         (emp.user?.role === 'MANAGER' && emp.id !== employee.id)
       );

       // Get positions for the current department
       const positionsResponse = await api.get('/api/positions/');
       const relevantPositions = positionsResponse.data.filter(
         pos => pos.department === employee.user?.department?.id
       );

       setDepartments(depts);
       setManagers(managersList);
       setPositions(relevantPositions);

       // Set form data last
       setFormData({
         name: employee.user?.name || '',
         department_id: employee.user?.department?.id || '',
         position_id: employee.user?.position?.id || '',
         role: employee.user?.role || 'EMPLOYEE',
         manager_id: employee.manager?.id || '',
         is_active: employee.user?.is_active
       });

       setLoading(false);
       setIsDataLoaded(true);
     } catch (err) {
       console.error('Failed to load data:', err);
       setError('Failed to load form data');
       setLoading(false);
     }
   };

   fetchInitialData();
 }, [employee]);

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData(prev => ({
     ...prev,
     [name]: value
   }));

   // Clear error when field is modified
   setFieldErrors(prev => ({
     ...prev,
     [name]: ''
   }));

   // If changing department, update positions
   if (name === 'department_id') {
     const newDeptId = value;
     setFormData(prev => ({
       ...prev,
       department_id: newDeptId,
       position_id: '' // Reset position when department changes
     }));
     // Filter positions for new department
     const newPositions = positions.filter(
       pos => pos.department === newDeptId
     );
     setPositions(newPositions);
   }
 };

 const handleSubmit = async (e) => {
   e.preventDefault();
   setError('');
   setFieldErrors({});
   setLoading(true);

   try {
     // Update employee data including role
     await api.patch(`/api/employees/${employee.id}/`, {
       user: {
         name: formData.name,
         role: formData.role,
         is_active: formData.is_active
       },
       department_id: formData.department_id,
       position_id: formData.position_id
     });

     // Handle manager assignment if changed
     if (formData.manager_id !== employee.manager?.id) {
       await api.post(`/api/employees/${employee.id}/assign_manager/`, {
         manager_id: formData.manager_id || null
       });
     }

     onUserUpdated();
     onClose();
   } catch (err) {
     console.error('Error updating user:', err);
     
     if (err.response?.data?.error) {
       const errorData = err.response.data.error;
       const newFieldErrors = {};
       
       Object.entries(errorData).forEach(([field, errors]) => {
         if (Array.isArray(errors)) {
           newFieldErrors[field] = errors[0];
         } else {
           newFieldErrors[field] = errors;
         }
       });
       
       setFieldErrors(newFieldErrors);
     } else {
       setError(err.response?.data?.message || 'Failed to update user');
     }
   } finally {
     setLoading(false);
   }
 };

 // Show loading state
 if (loading || !isDataLoaded || !formData) {
   return (
     <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
       <DialogContent>
         <div className="flex justify-center items-center p-4">
           <CircularProgress />
         </div>
       </DialogContent>
     </Dialog>
   );
 }

 // Only render the form when all data is loaded
 return (
   <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
     <DialogTitle>Edit User</DialogTitle>
     <form onSubmit={handleSubmit}>
       <DialogContent className="space-y-4">
         {error && (
           <div className="text-red-500 text-sm mb-4">
             {error}
           </div>
         )}

         <TextField
           autoFocus
           name="name"
           label="Full Name"
           fullWidth
           value={formData.name}
           onChange={handleChange}
           required
           error={!!fieldErrors.name}
           helperText={fieldErrors.name}
         />

         <div className="text-gray-600 text-sm">
           Email: {employee.user?.email}
         </div>

         <FormControl fullWidth required>
           <InputLabel>Role</InputLabel>
           <Select
             name="role"
             value={formData.role}
             onChange={handleChange}
             label="Role"
           >
             {ROLES.map(role => (
               <MenuItem key={role} value={role}>
                 {role}
               </MenuItem>
             ))}
           </Select>
         </FormControl>

         <FormControl 
           fullWidth 
           required
           error={!!fieldErrors.department_id}
         >
           <InputLabel>Department</InputLabel>
           <Select
             name="department_id"
             value={formData.department_id}
             onChange={handleChange}
             label="Department"
           >
             {departments.map(dept => (
               <MenuItem key={dept.id} value={dept.id}>
                 {dept.name}
               </MenuItem>
             ))}
           </Select>
           {fieldErrors.department_id && (
             <div className="text-red-500 text-sm mt-1">
               {fieldErrors.department_id}
             </div>
           )}
         </FormControl>

         <FormControl 
           fullWidth 
           required
           error={!!fieldErrors.position_id}
         >
           <InputLabel>Position</InputLabel>
           <Select
             name="position_id"
             value={formData.position_id}
             onChange={handleChange}
             label="Position"
             disabled={!formData.department_id}
           >
             {positions.map(pos => (
               <MenuItem key={pos.id} value={pos.id}>
                 {pos.title}
               </MenuItem>
             ))}
           </Select>
           {fieldErrors.position_id && (
             <div className="text-red-500 text-sm mt-1">
               {fieldErrors.position_id}
             </div>
           )}
         </FormControl>

         <FormControl fullWidth>
           <InputLabel>Manager</InputLabel>
           <Select
             name="manager_id"
             value={formData.manager_id}
             onChange={handleChange}
             label="Manager"
           >
             <MenuItem value="">
               <em>None</em>
             </MenuItem>
             {managers.map(manager => (
               <MenuItem key={manager.id} value={manager.id}>
                 {manager.user.name} ({manager.user.department?.name})
               </MenuItem>
             ))}
           </Select>
         </FormControl>
       </DialogContent>

       <DialogActions className="p-4">
         <button
           type="button"
           onClick={onClose}
           className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
           disabled={loading}
         >
           Cancel
         </button>
         <button
           type="submit"
           className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
           disabled={loading}
         >
           {loading ? (
             <CircularProgress size={20} color="inherit" />
           ) : (
             'Save Changes'
           )}
         </button>
       </DialogActions>
     </form>
   </Dialog>
 );
};

export default EditUserModal;