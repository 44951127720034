import React, { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { ChevronLeft, ChevronRight, Maximize2, X } from 'lucide-react';

const MonitoringGallery = ({ title, images, type }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  
  const handleClose = () => setSelectedImage(null);
  
  const navigateImage = (direction) => {
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    const newIndex = direction === 'next' 
      ? (currentIndex + 1) % images.length 
      : (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex]);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        {images.length > 0 && (
          <span className="text-sm text-gray-500">
            Last updated: {new Date(images[0].timestamp).toLocaleString()}
          </span>
        )}
      </div>

      <div className="grid grid-cols-4 gap-4">
        {images.slice(0, 4).map((image) => (
          <div 
            key={image.id}
            className="relative group cursor-pointer aspect-video bg-gray-100 rounded-lg overflow-hidden"
            onClick={() => setSelectedImage(image)}
          >
            <img
              src={image.url}
              alt={`${type} at ${new Date(image.timestamp).toLocaleString()}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-200 flex items-center justify-center">
              <Maximize2 className="w-6 h-6 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
            </div>
            <span className="absolute bottom-2 right-2 text-xs text-white bg-black bg-opacity-50 px-2 py-1 rounded">
              {new Date(image.timestamp).toLocaleTimeString()}
            </span>
          </div>
        ))}
      </div>

      {/* Full screen image dialog */}
      {selectedImage && (
        <Dialog 
          open={!!selectedImage} 
          onClose={handleClose}
          maxWidth="xl"
          fullWidth
        >
          <div className="relative">
            <DialogContent className="p-0">
              <img
                src={selectedImage.url}
                alt={`${type} at ${new Date(selectedImage.timestamp).toLocaleString()}`}
                className="w-full h-auto"
              />
              <button
                onClick={handleClose}
                className="absolute top-4 right-4 p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
              >
                <X className="w-6 h-6" />
              </button>
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-4">
                <button
                  onClick={() => navigateImage('prev')}
                  className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={() => navigateImage('next')}
                  className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </div>
              <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 px-3 py-1 rounded text-white">
                {new Date(selectedImage.timestamp).toLocaleString()}
              </div>
            </DialogContent>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default MonitoringGallery;