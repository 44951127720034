import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertCircle, User, Monitor, Clock, Filter, Search } from 'lucide-react';
import { format } from 'date-fns';
import { getAlertImage } from '../../utils/imageUtils';

const AlertsPage = () => {
  const { employeeId } = useParams();
  const [selectedType, setSelectedType] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  
  // Example alert data with fixed alert types and severities
  const alerts = [
    {
      id: 1,
      type: 'UNAUTHORIZED_PERSON',
      timestamp: '2025-01-12T09:30:00',
      imageUrl: getAlertImage('UNAUTHORIZED_PERSON', 'high'),
      details: 'Unrecognized person detected in workspace',
      severity: 'high'
    },
    {
      id: 2,
      type: 'UNAUTHORIZED_PERSON',
      timestamp: '2025-01-12T10:15:00',
      imageUrl: getAlertImage('UNAUTHORIZED_PERSON', 'medium'),
      details: 'Unrecognized person detected in workspace',
      severity: 'medium'
    },
    {
      id: 3,
      type: 'UNAUTHORIZED_APP',
      timestamp: '2025-01-12T11:45:00',
      imageUrl: getAlertImage('UNAUTHORIZED_APP', 'medium'),
      details: 'Unauthorized application "Gaming App" was launched',
      appName: 'Gaming App',
      severity: 'medium'
    },
    {
      id: 4,
      type: 'UNAUTHORIZED_APP',
      timestamp: '2025-01-12T14:20:00',
      imageUrl: getAlertImage('UNAUTHORIZED_APP', 'low'),
      details: 'Unauthorized application "Social Media" was accessed',
      appName: 'Social Media',
      severity: 'low'
    }
  ];

  // Filter alerts based on type and search query
  const filteredAlerts = alerts.filter(alert => {
    const matchesType = selectedType === 'all' || alert.type === selectedType;
    const matchesSearch = alert.details.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  });

  // Stats for the overview
  const stats = {
    total: alerts.length,
    unauthorized_persons: alerts.filter(a => a.type === 'UNAUTHORIZED_PERSON').length,
    unauthorized_apps: alerts.filter(a => a.type === 'UNAUTHORIZED_APP').length,
    high_severity: alerts.filter(a => a.severity === 'high').length
  };

  const StatCard = ({ icon: Icon, title, value, variant }) => {
    const getVariantStyles = () => {
      switch (variant) {
        case 'person':
          return 'bg-red-50 text-red-600 border-red-100';
        case 'app':
          return 'bg-yellow-50 text-yellow-600 border-yellow-100';
        case 'critical':
          return 'bg-purple-50 text-purple-600 border-purple-100';
        default:
          return 'bg-gray-50 text-gray-600 border-gray-100';
      }
    };

    return (
      <div className={`rounded-xl border p-6 ${getVariantStyles()}`}>
        <div className="flex items-start gap-4">
          <div className="p-3 rounded-lg bg-white/50">
            <Icon className="w-6 h-6" />
          </div>
          <div>
            <h3 className="font-medium text-sm">{title}</h3>
            <p className="text-2xl font-semibold mt-1">{value}</p>
          </div>
        </div>
      </div>
    );
  };

  const AlertTypeFilter = () => (
    <div className="flex gap-2">
      <button
        onClick={() => setSelectedType('all')}
        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
          selectedType === 'all'
            ? 'bg-gray-900 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        }`}
      >
        All Alerts
      </button>
      <button
        onClick={() => setSelectedType('UNAUTHORIZED_PERSON')}
        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
          selectedType === 'UNAUTHORIZED_PERSON'
            ? 'bg-red-600 text-white'
            : 'bg-red-50 text-red-600 hover:bg-red-100'
        }`}
      >
        Unauthorized Persons
      </button>
      <button
        onClick={() => setSelectedType('UNAUTHORIZED_APP')}
        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
          selectedType === 'UNAUTHORIZED_APP'
            ? 'bg-yellow-600 text-white'
            : 'bg-yellow-50 text-yellow-600 hover:bg-yellow-100'
        }`}
      >
        Unauthorized Apps
      </button>
    </div>
  );

  const AlertCard = ({ alert }) => {
    const isPersonAlert = alert.type === 'UNAUTHORIZED_PERSON';
    
    const getSeverityStyles = () => {
      switch (alert.severity) {
        case 'high':
          return 'bg-red-100 text-red-700';
        case 'medium':
          return 'bg-yellow-100 text-yellow-700';
        case 'low':
          return 'bg-blue-100 text-blue-700';
        default:
          return 'bg-gray-100 text-gray-700';
      }
    };
    
    return (
      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        <div className="p-6">
          <div className="flex items-start gap-4">
            <div className={`p-3 rounded-xl flex-shrink-0 ${
              isPersonAlert ? 'bg-red-50 text-red-600' : 'bg-yellow-50 text-yellow-600'
            }`}>
              {isPersonAlert ? (
                <User className="w-5 h-5" />
              ) : (
                <Monitor className="w-5 h-5" />
              )}
            </div>
            
            <div className="flex-1 min-w-0">
              <div className="flex items-start justify-between gap-4">
                <div>
                  <div className="flex items-center gap-3">
                    <h3 className="text-lg font-semibold text-gray-900">
                      {isPersonAlert ? 'Unauthorized Person Detected' : 'Unauthorized Application Usage'}
                    </h3>
                    <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getSeverityStyles()}`}>
                      {alert.severity.charAt(0).toUpperCase() + alert.severity.slice(1)}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    {format(new Date(alert.timestamp), 'MMMM d, yyyy')} at {' '}
                    {format(new Date(alert.timestamp), 'h:mm a')}
                  </p>
                </div>
              </div>
              
              <p className="text-gray-600 mt-4 mb-6">{alert.details}</p>
              
              <div className="rounded-lg overflow-hidden border border-gray-200">
                <img 
                  src={alert.imageUrl} 
                  alt={isPersonAlert ? "Security camera snapshot" : "Screen capture"} 
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          {employeeId ? "Employee Alerts" : "My Alerts"}
        </h1>
        <p className="mt-1 text-sm text-gray-600">
          Review and manage security alerts
        </p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          icon={AlertCircle}
          title="Total Alerts"
          value={stats.total}
          variant="default"
        />
        <StatCard
          icon={User}
          title="Unauthorized Persons"
          value={stats.unauthorized_persons}
          variant="person"
        />
        <StatCard
          icon={Monitor}
          title="Unauthorized Apps"
          value={stats.unauthorized_apps}
          variant="app"
        />
        <StatCard
          icon={AlertCircle}
          title="High Severity"
          value={stats.high_severity}
          variant="critical"
        />
      </div>

      {/* Filters and Search */}
      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-6">
        <AlertTypeFilter />
        
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search alerts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg w-full sm:w-64
                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      {/* Alerts List */}
      <div className="space-y-6">
        {filteredAlerts.length > 0 ? (
          filteredAlerts.map(alert => (
            <AlertCard key={alert.id} alert={alert} />
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
            <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No alerts found</h3>
            <p className="text-gray-600">
              {searchQuery 
                ? "No alerts match your search criteria" 
                : "No alerts have been recorded yet"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertsPage;