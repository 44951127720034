// src/components/dashboard/VisTimelineView.jsx
import React, { useRef, useEffect } from 'react';
import { Timeline, DataSet } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.css';
import moment from 'moment';

const VisTimelineView = ({ timeline, workDay }) => {
  const containerRef = useRef(null);
  const timelineRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const groups = new DataSet(
      timeline.map((track, i) => ({
        id: i,
        content: `<div class="group-item">
          <div class="font-medium text-sm">${track.name}</div>
          <div class="text-xs text-gray-500">${track.category}</div>
        </div>`
      }))
    );

    const items = new DataSet(
      timeline.flatMap((track, groupIndex) => 
        track.intervals.map((interval, i) => ({
          id: `${groupIndex}-${i}`,
          group: groupIndex,
          start: new Date(interval.start),
          end: new Date(interval.end),
          type: 'range',
          className: 'timeline-item',
          title: `${moment(interval.start).format('hh:mm A')} - ${moment(interval.end).format('hh:mm A')}`
        }))
      )
    );

    // Calculate time boundaries
    const dayStart = moment(workDay.stats.startTime).startOf('day').add(7, 'hours');
    const dayEnd = moment(workDay.stats.startTime).startOf('day').add(22, 'hours');

    const options = {
      start: dayStart,
      end: dayEnd,
      min: dayStart,
      max: dayEnd,
      zoomMin: 1000 * 60 * 60, // 1 hour
      zoomMax: 1000 * 60 * 60 * 15, // 15 hours
      timeAxis: { scale: 'minute', step: 30 },
      orientation: { axis: 'top' },
      stack: false,
      showCurrentTime: true,
      showMajorLabels: true,
      showMinorLabels: true,
      margin: { item: 10 },
      verticalScroll: true,
      horizontalScroll: true,
      zoomable: false,
      moveable: true,
      tooltip: {
        followMouse: true,
        overflowMethod: 'cap'
      },
      height: '400px',
      groupHeightMode: 'fixed',
      format: {
        minorLabels: {
          minute: 'h:mm A',
          hour: 'h:mm A'
        },
        majorLabels: {
          hour: 'h A'
        }
      }
    };

    // Initialize timeline
    timelineRef.current = new Timeline(container, items, groups, options);

    // Add event listeners
    const handleClick = (properties) => {
      if (properties.item) {
        const item = items.get(properties.item);
        // TODO: Show popup with details
        console.log('Clicked item:', item);
      }
    };

    timelineRef.current.on('click', handleClick);

    // Add custom styles
    const style = document.createElement('style');
    style.textContent = `
      .timeline-item {
        background-color: rgba(59, 130, 246, 0.1) !important;
        border: 1px solid rgba(59, 130, 246, 0.2) !important;
        border-radius: 4px !important;
        color: transparent !important;
      }
      .group-item {
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .vis-time-axis .vis-grid.vis-minor {
        border-width: 1px;
        border-color: #f3f4f6;
      }
      .vis-time-axis .vis-grid.vis-major {
        border-width: 1px;
        border-color: #e5e7eb;
      }
      .vis-timeline {
        border: 1px solid #e5e7eb !important;
        border-radius: 0.5rem;
        font-family: inherit;
      }
      .vis-panel.vis-center {
        border-color: #e5e7eb;
      }
      .vis-label {
        border-bottom: 1px solid #f3f4f6;
      }
      .vis-label.vis-group-level-0 {
        background-color: white;
      }
      .vis-time-axis .vis-text {
        color: #6b7280;
      }
    `;
    document.head.appendChild(style);

    return () => {
      if (timelineRef.current) {
        timelineRef.current.destroy();
      }
      document.head.removeChild(style);
    };
  }, [timeline, workDay]);

  return (
    <div ref={containerRef} className="w-full bg-white rounded-lg overflow-hidden" />
  );
};

export default VisTimelineView;