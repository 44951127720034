// In src/components/ui/dialog.jsx
import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

export const Dialog = ({
  children,
  open,
  onOpenChange,
  modal = true,
}) => {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0 bg-black/50 z-50" />
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {children}
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export const DialogContent = ({ children, className = '' }) => {
  return (
    <DialogPrimitive.Content className={`bg-white rounded-lg shadow-lg max-w-lg w-full mx-4 ${className}`}>
      {children}
    </DialogPrimitive.Content>
  );
};

export const DialogHeader = ({ children }) => {
  return (
    <div className="relative px-6 pt-6">
      {children}
    </div>
  );
};

export const DialogTitle = ({ children }) => {
  return (
    <DialogPrimitive.Title className="text-lg font-semibold text-gray-900">
      {children}
    </DialogPrimitive.Title>
  );
};

// Optional - add if you want animations
const DialogAnimation = ({ children }) => {
  return (
    <div className="animate-in fade-in-0 zoom-in-95">
      {children}
    </div>
  );
};