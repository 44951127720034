import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { 
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import ProjectTaskSelect from './ProjectTaskSelect';
import useAuthStore from '../../store/authStore';
import { timeTrackingApi } from '../../api/timeTracking';

const AddTimeModal = ({ 
  isOpen, 
  onClose, 
  date, 
  initialProject = null,
  initialTask = null,
  onTimeAdded 
}) => {
  const { user } = useAuthStore();
  const [project, setProject] = useState(initialProject);
  const [task, setTask] = useState(initialTask);
  const [startTime, setStartTime] = useState('09:00');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Basic validation
      if (!startTime) {
        throw new Error('Start time is required');
      }

      // Create the full datetime string
      const startDateTime = new Date(
        `${format(date, 'yyyy-MM-dd')}T${startTime}`
      ).toISOString();

      // Check for future date
      if (new Date(startDateTime) > new Date()) {
        throw new Error('Cannot add time entries for future dates');
      }

      // Create the manual time entry
      await timeTrackingApi.createManualTimeEntry({
        project: project,
        task: task,
        start_time: startDateTime
      });

      onTimeAdded?.();
      onClose();
    } catch (err) {
      console.error('Error creating time entry:', err);
      setError(
        err.response?.data?.message || 
        err.message || 
        'Failed to create time entry'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Time Entry</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          {error && (
            <Box sx={{ 
              mb: 2, 
              p: 2, 
              bgcolor: 'error.light', 
              color: 'error.main', 
              borderRadius: 1 
            }}>
              {error}
            </Box>
          )}

          <TextField
            fullWidth
            label="Date"
            value={format(date, 'MMMM d, yyyy')}
            disabled
            margin="normal"
          />

          <ProjectTaskSelect
            selectedProject={project}
            selectedTask={task}
            onProjectChange={setProject}
            onTaskChange={setTask}
          />

          <TextField
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }}
          />

          <TextField
            fullWidth
            label="Description"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="What did you work on? (Optional)"
            margin="normal"
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}>
            <Button 
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Save Entry'
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddTimeModal;